import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { history } from '../../App';
import service from '../../helpers/Api/FetchInterceptor';

const API_URL = "/galleries"

// Define a thunk function for fetching data
export const fetchGallerys = createAsyncThunk(
  'gallerys/fetchGallerys',
  async (params) => {
    let result = await service.get(API_URL + "?" + params);
    return result.data;
  }
);

// Define a thunk function for creating data
export const fetchSpecificGallerys = createAsyncThunk(
  'gallerys/fetchSpecificGallerys',
  async (id) => {
    const result = await service.get(API_URL + "/" + id);
    return result.data;
  }
);
// Define a thunk function for creating data
export const createGallery = createAsyncThunk(
  'gallerys/createGallery',
  async (newGallery) => {
    const formData = new FormData();
    Object.keys(newGallery).forEach(key => formData.append(key, newGallery[key]));
    let result = await service({
      method:"post",
      url: API_URL,
      data: formData
    });
    return result.data;
  }
);

// Define a thunk function for updating data
export const updateGallery = createAsyncThunk(
  'gallerys/updateGallery',
  async (updatedGallery) => {
    const [id, values] =  updatedGallery;
    console.log("UPDATE", updatedGallery)
    const formData = new FormData();
    Object.keys(values).forEach(key => formData.append(key, values[key]));
    let result = await service({
      url: API_URL + "/" + id,
      method:"POST",
      data: formData
    });
    return result.data;
  }
);

// Define a thunk function for deleting data
export const deleteGallery = createAsyncThunk(
  'gallerys/deleteGallery',
  async (id) => {
    await service.delete(`${API_URL}/${id}`);
    return id;
  }
);

// Define a slice with initial state and reducers
const gallerysSlice = createSlice({
  name: 'gallerys',
  initialState: {
    single: {},
    list: [],
    pagination: {
      pageSize: 5,
      current: 0,
      total: 0,
    },
    isLoading: false,
    sort: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for fetching data
    builder
      .addCase(fetchGallerys.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchGallerys.fulfilled, (state, action) => {
        state.isLoading = false;
        const data = action.payload
        state.list = data?.data
        state.pagination = {
          pageSize: data?.per_page,
          current: data?.current_page,
          total: data?.total,
        }
      })
      .addCase(fetchGallerys.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Add reducers for fetching data
    builder
      .addCase(fetchSpecificGallerys.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSpecificGallerys.fulfilled, (state, action) => {
        state.isLoading = false;
        const data = action.payload
        state.single = data?.gallery
      })
      .addCase(fetchSpecificGallerys.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Add reducers for creating data
    builder
      .addCase(createGallery.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createGallery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list.push(action.payload);
        history.push("/galleries")
      })
      .addCase(createGallery.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Add reducers for updating data
    builder
      .addCase(updateGallery.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateGallery.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.list.findIndex(gallery => gallery.id === action.payload.id);
        if (index !== -1) {
          state.list[index] = action.payload;
        }
        history.push("/galleries")
      })
      .addCase(updateGallery.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(deleteGallery.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteGallery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list = state.list.filter(gallery => gallery.id !== action.payload);
      })
      .addCase(deleteGallery.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  }
})

// Export the action creators and the reducer
export const { } = gallerysSlice.actions;
export default gallerysSlice.reducer;
