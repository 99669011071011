import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  Row,
  Table,
} from "reactstrap"
import { Form as FormB } from 'react-bootstrap';
import Select from "react-select"
import Dropzone from "react-dropzone"
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from "yup";
import Switch from "react-switch"
import { createOrder, fetchProducts, getUsers, } from '../../store/actions'
import { message, Spin, Tag } from 'antd'
import AddProduct from './Component/AddProduct';

export class OrderCreate extends Component {
  static propTypes = {
    type: PropTypes.any
  }


  constructor(props) {
    super(props)
    this.state = {
      selectedFiles: [],
      isSubmiting: false,
      rows: [],
      addProductModal: false,
    }

    this.toggleAddProductModal = this.toggleAddProductModal.bind(this)
  }

  toggleAddProductModal() {
    this.setState(prevState => ({
      addProductModal: !prevState.addProductModal,
    }))
  }


  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    )

    this.setState({ selectedFiles: files })
  }

  getProductById(id) {
    return this.props.products?.find(i => i.id == id) || {}
  }

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  componentDidMount() {
    const { match } = this.props;
    if (this.props.type == "EDIT") {
      console.log('Getting ID', match.params.id)
      this.props.fetchSpecificOrders(match.params.id)
    }

    this.props.fetchUser("perPage=-1");
    this.props.fetchProducts("perPage=-1");
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Orders" breadcrumbItem={`${this.props.type == "ADD" ? 'Add' : 'Update'} Order`} />
            <Formik
              enableReinitialize={true}
              initialValues={{
                user_address_id: 1,
              }}
              validateOnChange
              validateOnBlur
              // validateOnMount
              validationSchema={
                Yup.object().shape({
                  user_id: Yup.string().required('Please Select user'),
                  user_address_id: Yup.string().required('User Address is required'),
                  order_type: Yup.mixed().required('Order Type is required'),
                  payment_mode: Yup.mixed().required('Payment Mode is required'),
                  product_ids: Yup.array().of(
                    Yup.array().length(2).required()
                  ).required()
                })}
              onSubmit={values => {
                const that = this;
                this.setState({ isSubmiting: true })
                console.log(values, "THIS IS MAKE")
                const copyValue = {...values}

                copyValue.product_ids = copyValue.product_ids.map(i => `[${i[0]}, ${i[1]}]`)

                this.props.createOrder(copyValue)

              }}
            >
              {({ errors, status, touched, handleChange, handleBlur, setFieldValue, validateForm, handleSubmit, values, }) => (
                <Row>
                  {console.log(errors, "ERRORS")}
                  <Col xs="12">
                    <Spin spinning={this.props.isLoading}>
                      <Card>
                        <CardBody>
                          <CardTitle className="h1 mb-3">Basic Information</CardTitle>

                          <Form className="form-horizontal">
                            <Row>

                              <Col sm="6">
                                <FormB.Group>
                                  <FormB.Label>User</FormB.Label>
                                  <FormB.Control as="select" name="user_id" value={values.user_id} onChange={handleChange} onBlur={handleBlur} isInvalid={touched.user_id && errors.user_id}>
                                    <option value="">--Select--</option>
                                    {this.props.users?.map(prd => (
                                      <option value={prd.id} key={prd.id}>
                                        {prd.name} : {prd.email}
                                      </option>
                                    ))}
                                  </FormB.Control>
                                  <FormB.Control.Feedback type="invalid">{errors.user_id}</FormB.Control.Feedback>
                                </FormB.Group>

                              </Col>
                            </Row>
                            <Row className='mt-3'>

                              <Col sm="6">
                                <div className="mb-3">
                                  <FormB.Group>
                                    <FormB.Label>Order Type</FormB.Label>
                                    <FormB.Control as="select" name="order_type" value={values.order_type} onChange={handleChange} onBlur={handleBlur} isInvalid={touched.order_type && errors.order_type}>
                                      <option value="">--Select--</option>
                                      <option value="Manual">Manual</option>

                                    </FormB.Control>
                                    <FormB.Control.Feedback type="invalid">{errors.order_type}</FormB.Control.Feedback>
                                  </FormB.Group>
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <FormB.Group>
                                    <FormB.Label>Payment Mode</FormB.Label>
                                    <FormB.Control as="select" name="payment_mode" value={values.payment_mode} onChange={handleChange} onBlur={handleBlur} isInvalid={touched.payment_mode && errors.payment_mode}>
                                      <option value="">--Select--</option>
                                      <option value="Manual">Manual</option>

                                    </FormB.Control>
                                    <FormB.Control.Feedback type="invalid">{errors.payment_mode}</FormB.Control.Feedback>
                                  </FormB.Group>
                                </div>
                              </Col>
                            </Row>

                            <AddProduct show={this.state.addProductModal} handleClose={this.toggleAddProductModal} handleFormSubmit={(newVal) => {
                              setFieldValue("product_ids", [...values?.product_ids || [], [newVal.product, newVal.quantity]])
                              console.log("HANDLE SUBMIT", values)
                            }}></AddProduct>




                          </Form>

                        </CardBody>
                      </Card>
                    </Spin>
                  </Col>
                  <Col lx="12">
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-3 h5">Order Products</CardTitle>

                        {errors.product_ids && (
                          <span className='text-danger'>
                            Please Add Peoducts
                          </span>
                        )}

                        <Row className="mb-4">
                          <Col sm="4">
                          </Col>
                          <Col sm="8">
                            <div className="text-sm-end">

                              <Button
                                color="success"
                                className="font-16 btn-block btn btn-success"
                                onClick={this.toggleAddProductModal}
                              >
                                <i className="mdi mdi-plus-circle-outline me-1" />
                                Create New Orderzz
                              </Button>
                            </div>
                          </Col>
                        </Row>

                        <div className="table-responsive">
                          <Table className="table align-middle mb-0 table-nowrap">
                            <thead className="thead-light">
                              <tr>
                                <th>Product</th>
                                <th>Product Name</th>
                                <th>Price</th>
                                <th>Quantity</th>
                                <th>Total</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {values.product_ids?.map((prd, index) => (

                                <tr key={index}>
                                  <td>
                                    <img
                                      src={this.getProductById(prd[0]).thumbnail}
                                      alt="product-img"
                                      title="product-img"
                                      className="avatar-md"
                                    />
                                  </td>
                                  <td>
                                    <h5 className="font-size-14 text-truncate">
                                      <Link
                                        to={"/ecommerce-product-details/" + this.getProductById(prd[0]).id}
                                        className="text-dark"
                                      >
                                        {this.getProductById(prd[0]).name}
                                      </Link>
                                    </h5>
                                  </td>
                                  <td>{this.getProductById(prd[0]).b_to_b_price}</td>
                                  <td>
                                    <div style={{ width: "120px" }}>
                                      {prd[1]}
                                    </div>
                                  </td>
                                  <td>{this.getProductById(prd[0]).b_to_c_price * prd[1]}</td>
                                  <td>
                                    <div className="text-dark" to={"#"}>
                                      <i
                                        className="mdi mdi-delete font-size-18 cursor-pointer"
                                        id="edittooltip"
                                        onClick={function () {
                                          setFieldValue("product_ids", values?.product_ids?.filter((val, i) => i != index))
                                        }}
                                      ></i>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>

                          <div className="d-flex flex-wrap gap-2 mt-5">
                            <Button
                              type="submit"
                              color="primary"
                              disabled={this.props.isLoading}
                              onClick={handleSubmit}
                            >

                              {this.props.isLoading ? ' Creatring....' : 'Create Order'}
                            </Button>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

              )}
            </Formik>
          </Container>
        </div>
      </React.Fragment >
    )
  }
}

const mapStateToProps = (state) => ({
  order: state.Order.single,
  isLoading: state.Order.isLoading,
  users: state.User.list,
  products: state.Product.list,
})

const mapDispatchToProps = dispatch => ({
  createOrder: payload => dispatch(createOrder(payload)),
  fetchUser: (params) => dispatch(getUsers(params)),
  fetchProducts: (params) => dispatch(fetchProducts(params)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderCreate))

