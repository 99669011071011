import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { history } from '../../App';
import service from '../../helpers/Api/FetchInterceptor';

const API_URL = "/query_list"

// Define a thunk function for fetching data
export const fetchQuerys = createAsyncThunk(
  'querys/fetchQuerys',
  async (params) => {
    let result = await service.get(API_URL + "?" + params);
    return result.data;
  }
);

// Define a thunk function for creating data
export const fetchSpecificQuerys = createAsyncThunk(
  'querys/fetchSpecificQuerys',
  async (id) => {
    const result = await service.get(API_URL + "/" + id);
    return result.data;
  }
);
// Define a thunk function for creating data
export const createQuery = createAsyncThunk(
  'querys/createQuery',
  async (newQuery) => {
    const formData = new FormData();
    Object.keys(newQuery).forEach(key => formData.append(key, newQuery[key]));
    let result = await service({
      method:"post",
      url: API_URL,
      data: formData
    });
    return result.data;
  }
);

// Define a thunk function for updating data
export const updateQuery = createAsyncThunk(
  'querys/updateQuery',
  async (updatedQuery) => {
    const [id, values] =  updatedQuery;
    console.log("UPDATE", updatedQuery)
    const formData = new FormData();
    Object.keys(values).forEach(key => formData.append(key, values[key]));
    let result = await service({
      url: API_URL + "/" + id,
      method:"POST",
      data: formData
    });
    return result.data;
  }
);

// Define a thunk function for deleting data
export const queryMarkAsReaded = createAsyncThunk(
  'querys/queryMarkAsReaded',
  async (id) => {
    await service.get(`${API_URL}/${id}`);
    return id;
  }
);

// Define a slice with initial state and reducers
const querysSlice = createSlice({
  name: 'querys',
  initialState: {
    single: {},
    list: [],
    pagination: {
      pageSize: 5,
      current: 0,
      total: 0,
    },
    isLoading: false,
    sort: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for fetching data
    builder
      .addCase(fetchQuerys.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchQuerys.fulfilled, (state, action) => {
        state.isLoading = false;
        const data = action.payload
        state.list = data?.data
        state.pagination = {
          pageSize: data?.per_page,
          current: data?.current_page,
          total: data?.total,
        }
      })
      .addCase(fetchQuerys.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Add reducers for fetching data
    builder
      .addCase(fetchSpecificQuerys.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSpecificQuerys.fulfilled, (state, action) => {
        state.isLoading = false;
        const data = action.payload
        state.single = data?.query
      })
      .addCase(fetchSpecificQuerys.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Add reducers for creating data
    builder
      .addCase(createQuery.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list.push(action.payload);
        history.push("/querys")
      })
      .addCase(createQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Add reducers for updating data
    builder
      .addCase(updateQuery.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.list.findIndex(query => query.id === action.payload.id);
        if (index !== -1) {
          state.list[index] = action.payload;
        }
        history.push("/querys")
      })
      .addCase(updateQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(queryMarkAsReaded.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(queryMarkAsReaded.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list = state.list.map(query => {
          if(query.id == action.payload) {
            query.is_readed = 1
          } 
          return query
        });
      })
      .addCase(queryMarkAsReaded.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  }
})

// Export the action creators and the reducer
export const { } = querysSlice.actions;
export default querysSlice.reducer;
