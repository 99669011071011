import React, { Component } from "react"
import { connect } from "react-redux"
import { map, isEmpty, size } from "lodash"
import PropTypes, { object } from "prop-types"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  Row,
  Table,
} from "reactstrap"
import { Link, withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Product Images
import images from "../../assets/images"
import { fetchSpecificOrders } from "store/actions"
import { Spin, Tag } from "antd"
import moment from "moment"
import { OrderStaus } from "./Component/OrderStatus"
// import { getCartData } from "../../store/actions"

const orderSummary = {}

class OrderDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      productList: [],
      status_update_modal: false,
      orderId: 0,
      order_status: ""
    }

    this.tog_status_update = this.tog_status_update.bind(this)
  }

  componentDidMount() {
    const { match } = this.props;
    this.props.fetchSpecificOrders(match.params.id)
    const {
      cartData: { products },
      // onGetCartData,
    } = this.props
    // onGetCartData()
    this.setState({ productList: products })

    // this.props.fetchSpecificOrders
  }

  componentDidUpdate(prevProps) {
    const {
      cartData: { products },
    } = this.props
    if (
      !isEmpty(products) &&
      size(products) !== size(prevProps.cartData.products)
    ) {
      this.setState({ productList: products })
    }
  }

  removeCartItem = id => {
    let productList = this.state.productList
    const filtered = productList.filter(function (item) {
      return item.id !== id
    })

    this.setState({ productList: filtered })
  }

  countUP = (id, prev_data_attr) => {
    this.setState({
      productList: this.state.productList.map(p =>
        p.id === id ? { ...p, data_attr: prev_data_attr + 1 } : p
      ),
    })
  }

  countDown = (id, prev_data_attr) => {
    this.setState({
      productList: this.state.productList.map(p =>
        p.id === id ? { ...p, data_attr: prev_data_attr - 1 } : p
      ),
    })
  }

  tog_status_update(order) {
    this.setState(prevState => ({
      status_update_modal: !prevState.status_update_modal,
      orderId: order?.orderId || 0,
      order_status: order?.order_status || "",
    }))
  }

  render() {

    //meta title
    document.title = "Order Details";

    const {
      cartData: { orderSummary },
    } = this.props
    const { productList } = this.state

    const ord = this.props.order;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Dashboard" breadcrumbItem="Order Details" />
            <Spin spinning={this.props.isLoading}>

              <Modal
                isOpen={this.state.status_update_modal}
                toggle={this.tog_status_update}
                centered={true}
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0">Order Status</h5>
                  <button
                    type="button"
                    onClick={() =>
                      this.tog_status_update()
                    }
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <OrderStaus orderId={this.state.orderId} orderStatus={this.state.order_status} toggle={this.tog_status_update}></OrderStaus>
                </div>
              </Modal>
              <Row>

                <Col xl="4">
                  <Card className="card-full-y">
                    <CardBody>
                      <CardTitle className="mb-4 h5">Order Details</CardTitle>
                      <div className="left-right-item mt-3">
                        <h6 className="mb-0">Order Id</h6>
                        <h6 className="mb-0">{ord.id}</h6>
                      </div>
                      <div className="mt-2 left-right-item">
                        <h6 className="mb-0">Order Type</h6>
                        <h6 className="mb-0">
                          {ord.order_type == "MANUAL" ? (
                            <Tag color="pink">
                              {ord.order_type}
                            </Tag>
                          ) : (
                            <Tag color="blue">
                              {ord.order_type}
                            </Tag>
                          )}
                        </h6>
                      </div>
                      <div className="mt-2 left-right-item">
                        <h6 className="mb-0">Payment mode</h6>
                        <h6 className="mb-0">
                          {ord.payment_mode == "MANUAL" ? (
                            <Tag color="pink">
                              {ord.payment_mode}
                            </Tag>
                          ) : (
                            <Tag color="blue">
                              {ord.payment_mode}
                            </Tag>
                          )}
                        </h6>
                      </div>
                      {/* <div className="mt-2 left-right-item">
                        <h6 className="mb-0">Order Type</h6>
                        <h6 className="mb-0">{ord.order_type}</h6>
                      </div>
                      <div className="mt-2 left-right-item">
                        <h6 className="mb-0">Payment mode</h6>
                        <h6 className="mb-0">{ord.payment_mode}</h6>
                      </div> */}
                      <div className="mt-2 left-right-item">
                        <h6 className="mb-0">Canceled</h6>
                        <h6 className="mb-0">
                          {ord.is_canceled == 1 ? (
                            <Tag color={"red"}>Yes</Tag>
                          ) : (
                            <Tag color={"green"}>No</Tag>
                          )}
                        </h6>
                      </div>
                      <div className="mt-2 left-right-item">
                        <h6 className="mb-0">Partial</h6>
                        <h6 className="mb-0">
                          {ord.partial == 1 ? (
                            <Tag color={"red"}>Yes</Tag>
                          ) : (
                            <Tag color={"green"}>No</Tag>
                          )}
                        </h6>
                      </div>
                      <div className="mt-2 left-right-item">
                        <h6 className="mb-0">Order Time</h6>
                        <h6 className="float-end mb-0">
                          {/* test */}
                          <Tag>{moment(ord.created_at).format("YYYY-MM-DD HH:mm A")}</Tag>
                        </h6>
                      </div>
                    </CardBody>
                  </Card>
                  {/* <Card className="card-full-y">
                    <CardBody>
                      <CardTitle className="mb-3 h4">Order Summary</CardTitle>
                      {orderSummary && (
                        <div className="table-responsive">
                          <Table className="table mb-0">
                            <tbody>
                              <tr>
                                <td>Grand Total :</td>
                                <td>{orderSummary.grandTotal}</td>
                              </tr>
                              <tr>
                                <td>Discount :</td>
                                <td>- {orderSummary.discount}</td>
                              </tr>
                              <tr>
                                <td>Shipping Charge :</td>
                                <td>{orderSummary.shippingCharge}</td>
                              </tr>
                              <tr>
                                <td>Estimated Tax :</td>
                                <td>{orderSummary.estimatedTax}</td>
                              </tr>
                              <tr>
                                <th>Total :</th>
                                <td>{orderSummary.total}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      )}
                    </CardBody>
                  </Card> */}
                </Col>
                <Col xl="4">
                  <Card className="card-full-y">
                    <CardBody>
                      <CardTitle className="mb-4 h5">Customer Details</CardTitle>
                      <div className="left-right-item mt-3">
                        <h6 className="mb-0">Customer Id</h6>
                        <h6 className="mb-0">{ord?.customer?.id}</h6>
                      </div>
                      <div className="left-right-item mt-2">
                        <h6 className="mb-0">Name</h6>
                        <h6 className="mb-0">{ord?.customer?.name}</h6>
                      </div>
                      <div className="left-right-item mt-2">
                        <h6 className="mb-0">Email</h6>
                        <h6 className="mb-0">{ord?.customer?.email}</h6>
                      </div>
                      <div className="left-right-item mt-2">
                        <h6 className="mb-0">Phone</h6>
                        <h6 className="mb-0">{ord?.customer?.phone}</h6>
                      </div>

                      <div className="left-right-item mt-2">
                        <h6 className="mb-0">Address</h6>
                        <h6 className="mb-0">{ord?.address}</h6>
                      </div>
                    </CardBody>
                  </Card>

                </Col>
                <Col xl="4">
                  <Card className="card-full-y">
                    <CardBody>
                      <CardTitle className="mb-4 h5">Order Amount</CardTitle>

                      {ord?.coupon?.code && (
                        <div className="left-right-item mt-2">
                          <h6 className="mb-0">Discount</h6>
                          <h6 className="mb-0">{ord?.coupon?.code}</h6>
                        </div>
                      )}
                      <div className="left-right-item mt-2">
                        <h6 className="mb-0">Total</h6>
                        <h6 className="mb-0">$ {ord?.order_total}</h6>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                {ord.payment_mode == "ONLINE" && (
                  <Col xl="6">
                    <Card className="card-full-y">
                      <CardBody>
                        <CardTitle className="mb-4 h5">Strip Information</CardTitle>
                        <>
                          <div className="left-right-item mt-2">
                            <h6 className="mb-0">Strip ID</h6>
                            <h6 className="mb-0">{ord?.payment_intent_id}</h6>
                          </div>
                          <div className="left-right-item mt-2">
                            <h6 className="mb-0">Strip Payment Status</h6>
                            <h6 className="mb-0">{ord?.payment_intent_status}</h6>
                          </div>
                        </>
                      </CardBody>
                    </Card>
                  </Col>
                )}
                <Col lx="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-3 h5">Order Products</CardTitle>
                      <div className="table-responsive">
                        <Table className="table align-middle mb-0 table-nowrap">
                          <thead className="thead-light">
                            <tr>
                              <th>Order Detail ID</th>
                              <th>Product</th>
                              <th>Product Name</th>
                              <th>Price</th>
                              <th>Quantity</th>
                              <th>Order Status</th>
                              <th>Return Status</th>
                              <th>Create Time</th>
                              <th>Total</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ord.order_details?.map(({ product, ...orderInstance }) => (
                              <tr key={orderInstance.id}>
                                <td>{orderInstance.id}</td>
                                <td>
                                  <img
                                    src={product.thumbnail}
                                    style={{ objectFit: 'contain' }}
                                    alt="product-img"
                                    title="product-img"
                                    className="avatar-md"
                                  />
                                </td>
                                <td>
                                  <h5 className="font-size-14 text-truncate">
                                    <Link
                                      to={"/ecommerce-product-details/" + product.id}
                                      className="text-dark"
                                    >
                                      {product.name}
                                    </Link>
                                  </h5>
                                </td>
                                <td>$ {Math.round(orderInstance.order_total / orderInstance.quantity)}</td>
                                <td>
                                  <div style={{ width: "120px" }}>
                                    {orderInstance.quantity}
                                  </div>
                                </td>
                                <td>
                                  <Tag>
                                    {orderInstance.order_status}
                                  </Tag>
                                </td>
                                <td>
                                  <Tag>
                                    {orderInstance.is_returned ? (
                                      <>Return</>
                                    ) : orderInstance.is_return_initiated ? (
                                      <>Return Initiated</>
                                    ) : (
                                      <></>
                                    )}
                                  </Tag>
                                </td>
                                <td>
                                  <Tag color="blue">{moment(orderInstance.created_at).format("YYYY-MM-DD HH:mm A")}</Tag>
                                </td>

                                <td>{orderInstance.order_total}</td>
                                <td>
                                  <div className="text-dark" to={"#"}>
                                    <i
                                      className="mdi mdi-pencil font-size-18"
                                      id="edittooltip"
                                      onClick={() => this.tog_status_update({
                                        orderId: orderInstance.id,
                                        order_status: orderInstance.order_status
                                      })}
                                    ></i>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                      {/* <Row className="mt-4">
                        <Col sm="6">
                          <Link
                            to="/ecommerce-products"
                            className="btn btn-secondary"
                          >
                            <i className="mdi mdi-arrow-left me-1" /> Continue Shopping{" "}
                          </Link>
                        </Col>
                        <Col sm="6">
                          <div className="text-sm-end mt-2 mt-sm-0">
                            <Link
                              to="/ecommerce-checkout"
                              className="btn btn-success"
                            >
                              <i className="mdi mdi-cart-arrow-right me-1" />{" "}
                              Checkout{" "}
                            </Link>
                          </div>
                        </Col>
                      </Row> */}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Spin>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

OrderDetails.propTypes = {
  cartData: PropTypes.any,
  onGetCartData: PropTypes.func,
}

const mapStateToProps = (state) => ({
  order: state.Order.single,
  isLoading: state.Order.isLoading,
  cartData: [],
})

const mapDispatchToProps = dispatch => ({
  // createOrder: payload => dispatch(createOrder(payload)),
  // updateOrder: (payload) => dispatch(updateOrder(payload)),
  fetchSpecificOrders: payload => dispatch(fetchSpecificOrders(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OrderDetails))
