import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { history } from '../../App';
import service from '../../helpers/Api/FetchInterceptor';

const API_URL = "/exibitors"

// Define a thunk function for fetching data
export const fetchExibitors = createAsyncThunk(
  'exibitors/fetchExibitors',
  async (params) => {
    let result = await service.get(API_URL + "?" + params);
    return result.data;
  }
);

// Define a thunk function for creating data
export const fetchSpecificExibitors = createAsyncThunk(
  'exibitors/fetchSpecificExibitors',
  async (id) => {
    const result = await service.get(API_URL + "/" + id);
    return result.data;
  }
);
// Define a thunk function for creating data
export const createExibitor = createAsyncThunk(
  'exibitors/createExibitor',
  async (newExibitor) => {
    const formData = new FormData();
    Object.keys(newExibitor).forEach(key => formData.append(key, newExibitor[key]));
    let result = await service({
      method:"post",
      url: API_URL,
      data: formData
    });
    return result.data;
  }
);

// Define a thunk function for updating data
export const updateExibitor = createAsyncThunk(
  'exibitors/updateExibitor',
  async (updatedExibitor) => {
    const [id, values] =  updatedExibitor;
    console.log("UPDATE", updatedExibitor)
    const formData = new FormData();
    Object.keys(values).forEach(key => formData.append(key, values[key]));
    let result = await service({
      url: API_URL + "/" + id,
      method:"POST",
      data: formData
    });
    return result.data;
  }
);

// Define a thunk function for deleting data
export const deleteExibitor = createAsyncThunk(
  'exibitors/deleteExibitor',
  async (id) => {
    await service.delete(`${API_URL}/${id}`);
    return id;
  }
);

// Define a slice with initial state and reducers
const exibitorsSlice = createSlice({
  name: 'exibitors',
  initialState: {
    single: {},
    list: [],
    pagination: {
      pageSize: 5,
      current: 0,
      total: 0,
    },
    isLoading: false,
    sort: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for fetching data
    builder
      .addCase(fetchExibitors.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchExibitors.fulfilled, (state, action) => {
        state.isLoading = false;
        const data = action.payload
        state.list = data?.data
        state.pagination = {
          pageSize: data?.per_page,
          current: data?.current_page,
          total: data?.total,
        }
      })
      .addCase(fetchExibitors.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Add reducers for fetching data
    builder
      .addCase(fetchSpecificExibitors.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSpecificExibitors.fulfilled, (state, action) => {
        state.isLoading = false;
        const data = action.payload
        state.single = data?.exibitor
      })
      .addCase(fetchSpecificExibitors.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Add reducers for creating data
    builder
      .addCase(createExibitor.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createExibitor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list.push(action.payload);
        history.push("/exibitors")
      })
      .addCase(createExibitor.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Add reducers for updating data
    builder
      .addCase(updateExibitor.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateExibitor.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.list.findIndex(exibitor => exibitor.id === action.payload.id);
        if (index !== -1) {
          state.list[index] = action.payload;
        }
        history.push("/exibitors")
      })
      .addCase(updateExibitor.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(deleteExibitor.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteExibitor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list = state.list.filter(exibitor => exibitor.id !== action.payload);
      })
      .addCase(deleteExibitor.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  }
})

// Export the action creators and the reducer
export const { } = exibitorsSlice.actions;
export default exibitorsSlice.reducer;
