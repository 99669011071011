import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { API_CALL } from "./actionTypes"
import { setLoading } from "./actions"

//Include Both Helper File with needed methods
import service from "../../helpers/Api/FetchInterceptor";

function* apiCall(action) {
  yield put(setLoading(true));
  try {
    const response = yield call(service, action.payload);
    yield put(success(response));
  } catch (error) {
    yield put(failure(error));
  } finally {
    yield put(setLoading(false));
  }

}

function* apiSaga() {
  yield takeEvery(API_CALL, apiCall)
}

export default apiSaga
