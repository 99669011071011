import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import Dropzone from "react-dropzone"
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from "yup";
import Switch from "react-switch"
import { createBanner, fetchSpecificBanner, updateBanner } from '../../store/actions'
import { message, Spin } from 'antd'
import service from 'helpers/Api/FetchInterceptor';
import { history } from 'App';
import SearchUser from './component/SearchUser';
import SearchCoupon from './component/SearchCouponr';

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      No
    </div>
  )
}

const OnSymbol = props => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      Yes
    </div>
  )
}

export class CouponForSpecificUserOperations extends Component {
  static propTypes = {
    type: PropTypes.any
  }


  constructor(props) {
    super(props)
    this.state = {
      selectedFiles: [],
      isSubmiting: false,
      isLoading: false,
      fetchData: {}
    }
  }

  componentDidMount() {
    const { match } = this.props;
    if (this.props.type == "EDIT") {
      console.log('Getting ID', match.params.id)
      this.fetchSpecificAPI(match.params.id)
    }
  }

  fetchSpecificAPI(id) {
    this.setState({
      isLoading: true
    })
    service.get('/user_coupons' + "/" + id)
      .then(res => {
        const result = res.data;
        this.setState({
          isLoading: false,
          fetchData: result.coupon
        })
      })
      .catch(err => {
        this.setState({
          isLoading: false,
        })
      })
  }

  createAPI(values) {

    const formData = new FormData();
    Object.keys(values).forEach(key => formData.append(key, values[key]));

    this.setState({
      isLoading: true
    })
    service({
      method: "POST",
      url: '/user_coupons',
      data: formData
    })
      .then(res => {
        const result = res.data;
        history.push("/coupon-users")
        this.setState({
          isLoading: false,
          fetchData: result.coupon
        })
      })
      .catch(err => {
        this.setState({
          isLoading: false,
        })
      })
  }

  updateAPI(list = []) {
    const [id, values] = list;
    console.log("UPDATE", list)
    const formData = new FormData();
    Object.keys(values).forEach(key => formData.append(key, values[key]));

    this.setState({
      isLoading: true
    })
    service({
      method: "POST",
      url: '/user_coupons' + "/" + id,
      data: formData
    })
      .then(res => {
        const result = res.data;
        history.push("/coupon-users")
        this.setState({
          isLoading: false,
          fetchData: result.coupon
        })
      })
      .catch(err => {
        this.setState({
          isLoading: false,
        })
      })
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Specific User Coupon" breadcrumbItem={`${this.props.type == "ADD" ? 'Add' : 'Update'} Specific User Coupon`} />
            {console.log(this.state.fetchData, "FETCH DATA")}
            <Row>
              <Col xs="12">
                <Spin spinning={this.state.isLoading}>
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">Basic Information</CardTitle>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          banner_type: "b_to_b",
                          ...(this.props.type == "EDIT" ? this.state.fetchData : {}),
                          ...(() => {
                            let returnObject = {};
                            if (this.state.fetchData.user?.id) {
                              returnObject.user_id = { value: this.state.fetchData.user?.id || "", label: `#${this.state.fetchData.user.id} || ${this.state.fetchData.user.email} || ${this.state.fetchData.user.name}` || "" }
                            }
                            if (this.state.fetchData.coupon?.id) {
                              returnObject.coupon_id = { value: this.state.fetchData.coupon?.id  || "", label: this.state.fetchData.coupon?.code  || "" }
                            }
                            return returnObject;
                            // if()
                          })(),
                        }}
                        validationSchema={
                          Yup.object().shape({
                            // user_id: Yup.string().required('User is required'),
                            // coupon_id: Yup.string().required('Coupon is required')
                          })}
                        onSubmit={values => {
                          const that = this;
                          values.coupon_id = values.coupon_id.value
                          values.user_id = values.user_id.value
                          this.setState({ isSubmiting: true })
                          let apiSubmit = null;
                          if (this.props.type == "EDIT") {
                            if (!(values.banner instanceof File)) {
                              delete values['banner']
                            }
                            values._method = 'patch'
                            this.updateAPI([this.props.match.params.id, values])
                          } else {
                            this.createAPI(values)
                          }
                        }}
                      >
                        {({ errors, status, touched, setFieldValue, validateForm, values, }) => (
                          <Form className="form-horizontal">
                            <Row>

                              <Col sm="12">
                                <div className="mb-3">
                                  <SearchUser values={values} setFieldValue={setFieldValue} label='Select User:' formikKeyName='user_id' />
                                </div>
                                <ErrorMessage name="user_id" render={msg => <div className="error">{msg}</div>} />
                              </Col>
                              <Col sm="12">
                                <div className="mb-3">
                                  <SearchCoupon values={values} setFieldValue={setFieldValue} label='Select Coupon:' formikKeyName='coupon_id' />
                                </div>
                                <ErrorMessage name="coupon_id" render={msg => <div className="error">{msg}</div>} />
                              </Col>
                            </Row>

                            <div className="d-flex flex-wrap gap-2 mt-4">
                              <Button
                                type="submit"
                                color="primary"
                                disabled={this.props.isLoading}
                              >

                                {this.props.isLoading ? ' Saving....' : 'Save Changes'}
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Formik>

                    </CardBody>
                  </Card>
                </Spin>

              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment >
    )
  }
}

const mapStateToProps = (state) => ({
  // banner: state.Banner.single,
  // isLoading: state.Banner.isLoading
})

const mapDispatchToProps = dispatch => ({
  // createBanner: payload => dispatch(createBanner(payload)),
  // updateBanner: (payload) => dispatch(updateBanner(payload)),
  // fetchSpecificBanner: payload => dispatch(fetchSpecificBanner(payload))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CouponForSpecificUserOperations))

