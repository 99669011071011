import axios from 'axios'
import { notification } from 'antd';

const API_URL = process.env.REACT_APP_API_URL || "";

const service = axios.create({
	baseURL: API_URL,
	timeout: 60000
})

const AUTH_TOKEN = "authUser"

// Config
const TOKEN_PAYLOAD_KEY = 'authorization'
const AUTH_TYPE = 'Bearer'
const PUBLIC_REQUEST_KEY = 'public-request'

// API Request interceptor
service.interceptors.request.use(config => {

	const obj = JSON.parse(localStorage.getItem(AUTH_TOKEN))
	// const jwtToken = localStorage.getItem(AUTH_TOKEN)
	const jwtToken = obj?.accessToken

	if (jwtToken) {
		config.headers[TOKEN_PAYLOAD_KEY] = AUTH_TYPE + " " + jwtToken
	}

	//   if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
	// 		history.push(ENTRY_ROUTE)
	// 		window.location.reload();
	//   }

	return config
}, error => {
	// Do something with request error here
	console.log(error.response)
	notification.error({
		message: 'Error'
	})
	Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use((response) => {
	let notificationParam = {
		message: ''
	}
	if (response.data.status == false) {
		notificationParam.message = 'Your account is not active'
		notificationParam.description = 'Please login again'
		localStorage.removeItem(AUTH_TOKEN);
		notification.error(notificationParam)
		// history.push('/auth/login')
		// throw ({
		// 	message: 'Authentication Fail',
		// 	description: 'Please login again'
		// })
		setTimeout(() => {
			window.location.reload();
		}, 2000);
		return Promise.reject(response)
	}

	return response.data
}, (error) => {

	const { message } = error.response.data
	// Do something with response error here
	let notificationParam = {
		message: ''
	}

	// Remove token and redirect 
	if (error.response?.status === 403 || error.response?.status === 401) {
		notificationParam.message = 'Not Authorized'
		// notificationParam.description = 'Please login again'
		// localStorage.removeItem(AUTH_TOKEN)
	}

	else if (error.response?.status === 404) {
		notificationParam.message = 'Not Found'
	}

	else if (error.response.status === 500) {
		notificationParam.message = 'Internal Server Error'
	}

	else if (error.response?.status === 508) {
		notificationParam.message = 'Time Out'
	}

	else {
		notificationParam.message = message
	}

	notification.error(notificationParam)

	return Promise.reject(error);
});

export default service