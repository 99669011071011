import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { history } from '../../App';
import service from '../../helpers/Api/FetchInterceptor';

const API_URL = "/order"

// Define a thunk function for fetching data
export const fetchOrders = createAsyncThunk(
  'orders/fetchOrders',
  async (params) => {
    let result = await service.get(API_URL + "?" + params);
    return result.data;
  }
);

// Define a thunk function for creating data
export const fetchSpecificOrders = createAsyncThunk(
  'orders/fetchSpecificOrders',
  async (id) => {
    const result = await service.get(API_URL + "/" + id);
    return result.data;
    // return Promise.resolve({
    //   "id": 12,
    //   "user_id": 1,
    //   "order_type": "MANUAL",
    //   "address": "6765 WTT \nMakarba Road\nAhmedabad Gujarat\n380001\n",
    //   "order_total": "190",
    //   "payment_mode": "MANUAL",
    //   "is_canceled": 0,
    //   "partial": 1,
    //   "created_at": "2023-04-27T05:21:57.000000Z",
    //   "updated_at": "2023-04-27T05:21:59.000000Z",
    //   "order_details": [
    //     {
    //       "id": 13,
    //       "order_id": 12,
    //       "product_id": 1,
    //       "order_total": "76",
    //       "quantity": "2",
    //       "discount": null,
    //       "amount_to_pay": "76",
    //       "in_stock": 0,
    //       "order_status": "Order recieved",
    //       "is_cancel_initiated": 0,
    //       "is_canceled": 0,
    //       "is_return_initiated": 0,
    //       "is_returned": 0,
    //       "created_at": "2023-04-27T05:21:59.000000Z",
    //       "updated_at": "2023-04-27T05:21:59.000000Z",
    //       "product": {
    //         "id": 1,
    //         "brand_id": 1,
    //         "category_id": 1,
    //         "zoho_id": "70187000001805025",
    //         "relative_product_id": 0,
    //         "product_code": "UU011243506",
    //         "name": "HP 98-98 Colour Printer",
    //         "sort_description": "this is test sort description",
    //         "long_description": "This is test long description of the test product 1",
    //         "thumbnail": "https://backend.vedasara.in/public/product/thumbnail/1681818866.jpeg",
    //         "slug": "HP 98-98 Colour Printer",
    //         "b_to_c_price": "50",
    //         "b_to_b_price": "38",
    //         "is_featured": 0,
    //         "is_active": 0,
    //         "created_at": "2023-04-18T11:54:26.000000Z",
    //         "updated_at": "2023-04-18T11:54:26.000000Z"
    //       }
    //     },
    //     {
    //       "id": 14,
    //       "order_id": 12,
    //       "product_id": 2,
    //       "order_total": "114",
    //       "quantity": "3",
    //       "discount": null,
    //       "amount_to_pay": "114",
    //       "in_stock": 1,
    //       "order_status": "Order recieved",
    //       "is_cancel_initiated": 0,
    //       "is_canceled": 0,
    //       "is_return_initiated": 0,
    //       "is_returned": 0,
    //       "created_at": "2023-04-27T05:21:59.000000Z",
    //       "updated_at": "2023-04-27T05:21:59.000000Z",
    //       "product": {
    //         "id": 2,
    //         "brand_id": 1,
    //         "category_id": 1,
    //         "zoho_id": "70187000001776064",
    //         "relative_product_id": 0,
    //         "product_code": "AT010575267",
    //         "name": "HP 98-98 Colour Printer 2",
    //         "sort_description": "this is test sort description",
    //         "long_description": "This is test long description of the test product 1",
    //         "thumbnail": "https://backend.vedasara.in/public/product/thumbnail/1681830385.jpeg",
    //         "slug": "HP 98-98 Colour Printer",
    //         "b_to_c_price": "50",
    //         "b_to_b_price": "38",
    //         "is_featured": 0,
    //         "is_active": 0,
    //         "created_at": "2023-04-18T15:06:25.000000Z",
    //         "updated_at": "2023-04-18T15:06:25.000000Z"
    //       }
    //     }
    //   ],
    //   "customer": {
    //     "id": 1,
    //     "name": "Master Admin",
    //     "email": "admin@auscorp.com",
    //     "email_verified_at": "2023-04-17T12:40:28.000000Z",
    //     "phone": "878559842",
    //     "type": "admin",
    //     "is_active": 1,
    //     "created_at": "2023-04-17T11:26:08.000000Z",
    //     "updated_at": "2023-04-17T11:26:08.000000Z"
    //   }
    // })
  }
);
// Define a thunk function for creating data
export const createOrder = createAsyncThunk(
  'orders/createOrder',
  async (newOrder) => {
    const formData = new FormData();
    // Object.keys(newOrder).forEach(key => formData.append(key, newOrder[key]));
    let result = await service({
      method: "post",
      url: API_URL,
      data: newOrder
    });
    return result.data;
  }
);

// Define a thunk function for updating data
export const updateOrder = createAsyncThunk(
  'orders/updateOrder',
  async (updatedOrder) => {
    const [id, values] = updatedOrder;
    console.log("UPDATE", updatedOrder)
    const formData = new FormData();
    Object.keys(values).forEach(key => formData.append(key, values[key]));
    let result = await service({
      url: API_URL + "/" + id,
      method: "POST",
      data: formData
    });
    return result.data;
  }
);

// Define a thunk function for deleting data
export const deleteOrder = createAsyncThunk(
  'orders/deleteOrder',
  async (id) => {
    await service.delete(`${API_URL}/${id}`);
    return id;
  }
);


export const updateOrderStatus = createAsyncThunk(
  'orders/updateOrderStatus',
  async ({ id, order_status }) => {
    const result = await service.patch(`${API_URL}/${id}`, {
      order_status
    });
    return result.data;
  }
);

// Define a slice with initial state and reducers
const ordersSlice = createSlice({
  name: 'orders',
  initialState: {
    single: {},
    list: [],
    pagination: {
      pageSize: 5,
      current: 0,
      total: 0,
    },
    isLoading: false,
    sort: {},
    error: null,
  },
  reducers: {
    deleteOrderOrderDetailsInsideArray: (state, action) => {
      console.log("START DELETE", action.payload)
      state.list.forEach((val, i) => {
        if (val.order_details?.find(v => v.id == action.payload)) {
          console.log("FIND DELETE", val, "INDEX: ", i)
          state.list[i] = {
            ...state.list[i],
            order_details: val.order_details.filter(x => x.id != action.payload)
          }
        }
      });
      state.list = [...state.list]
    },
  },
  extraReducers: (builder) => {
    // Add reducers for fetching data
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.isLoading = false;
        const data = action.payload
        state.list = data?.data
        state.pagination = {
          pageSize: data?.per_page,
          current: data?.current_page,
          total: data?.total,
        }
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Add reducers for fetching data
    builder
      .addCase(fetchSpecificOrders.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSpecificOrders.fulfilled, (state, action) => {
        state.isLoading = false;
        const data = action.payload
        state.single = data
      })
      .addCase(fetchSpecificOrders.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Add reducers for creating data
    builder
      .addCase(createOrder.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list.push(action.payload);
        history.push("/orders")
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Add reducers for updating data
    builder
      .addCase(updateOrder.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.list.findIndex(order => order.id === action.payload.id);
        if (index !== -1) {
          state.list[index] = action.payload;
        }
        history.push("/orders")
      })
      .addCase(updateOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });


    builder
      .addCase(updateOrderStatus.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateOrderStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.single.order_details.findIndex(order => order.id === action.payload.order_details.id);
        if (index !== -1) {
          state.single.order_details[index] = {
            ...state.single.order_details[index],
            ...action.payload.order_details
          };
        }
        // history.push("/orders")
      })
      .addCase(updateOrderStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(deleteOrder.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list = state.list.filter(order => order.id !== action.payload);
      })
      .addCase(deleteOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  }
})

// Export the action creators and the reducer
export const { deleteOrderOrderDetailsInsideArray } = ordersSlice.actions;
export default ordersSlice.reducer;
