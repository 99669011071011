import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import classNames from "classnames";

// Import FecthAPI
import service from "../../helpers/Api/FetchInterceptor";
import Lightbox from "react-image-lightbox";
import { deleteCoupon, fetchCoupons } from "../../store/actions";
import PaginateTableSaga from "../../components/UI/Table/TableSaga";
import { Popconfirm, Tag, Table, Dropdown } from "antd";
import { MoreOutlined, EditOutlined, DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons"

function Test(props) {
  const [visible, setVisible] = useState(false)
  return (
    <React.Fragment>
      <img src={props.row.logo} style={{ width: '200px' }} onClick={() => setVisible(true)} />
      {visible && (
        <Lightbox
          mainSrc={props.row.logo}
          enableZoom={false}
          imageCaption={props.row.company_name}
          onCloseRequest={() => { setVisible(false) }}
        />
      )}
    </React.Fragment>
  )
}

class Coupons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customQuery: {}
    };

    this.tableColumns = [
      {
        title: "ID",
        dataIndex: "id",
        sorter: true,
      },
      {
        title: "Code",
        dataIndex: "code",
        sorter: true,
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: true,
      },
      {
        title: "Discount",
        dataIndex: "discount_in_percentage",
        sorter: true,
      },
      {
        title: "Discount Upto",
        dataIndex: "discount_upto",
        sorter: true,
      },
      {
        title: "Valid Upto",
        dataIndex: "valid_upto",
        sorter: true,
      },
      {
        title: "No Of Uses",
        dataIndex: "no_of_uses",
        sorter: true,
      },
      // {
      //   title: "Type",
      //   dataIndex: "type",
      //   sorter: true,
      // },
      {
        title: "Status",
        dataIndex: "is_active",
        render: (text, row) => {
          return (
            <>
              {row.is_active == 1 ? (
                <Tag color={"green"}>Active</Tag>
              ) : (
                <Tag color={"red"}>In Active</Tag>
              )}
            </>
          );
        },
        sorter: true,
      },
      {
        title: "For Specific Users",
        dataIndex: "for_specific_users",
        render: (text, row) => {
          return (
            <>
              {row.for_specific_users == 1 ? (
                <Tag color={"green"}>Yes</Tag>
              ) : (
                <Tag color={"red"}>No</Tag>
              )}
            </>
          );
        },
        sorter: true,
      },
      {
        title: "Action",
        dataIndex: "",
        sorter: false,
        render: (_, row) => (
          <>
            <div className="d-flex gap-3">
              <Link className="text-success" to={`/coupon/${row.id}/edit`}>
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                // onClick={() => this.handleUserClick(user)}
                ></i>
              </Link>
              <Popconfirm
                title="Are you sure?"
                className="text-danger cursor-pointer"
                description="You want to Delete"
                onConfirm={() => this.props.deleteCoupon(row.id).payload}
                onOpenChange={() => console.log('open change')}
              >
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                // onClick={() => this.onClickDelete(user)}
                ></i>
              </Popconfirm>
            </div>
          </>
        )
      },
    ];

  }



  async fetchApi(params) {
    let result = await service.get('/coupons' + "?" + params);
    return result.data;
  }

  componentDidMount() {
    // this.props.fetchCoupon("");
  }

  componentDidUpdate(prevProps) {
  }

  render() {
    //meta title
    document.title = "Coupons";

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("Dashboards")}
              breadcrumbItem={this.props.t("Coupons")}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row className="mb-4">
                      <Col sm="4">
                      </Col>
                      <Col sm="8">
                        <div className="text-sm-end">
                          <Link to={'/coupon/add'}>
                            <Button
                              color="primary"
                              className="font-16 btn-block btn btn-primary"
                            >
                              <i className="mdi mdi-plus-circle-outline me-1" />
                              Create New Coupon
                            </Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                    <PaginateTableSaga
                      fetch={this.props.fetchCoupon}
                      isLoading={this.props.couponState.isLoading}
                      columns={this.tableColumns}
                      list={this.props.coupons}
                      pagination={this.props.couponState.pagination}
                      customeQuery={this.customQuery}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* Render Breadcrumb */}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Coupons.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = ({ Coupon }) => {
  return ({
    couponState: Coupon,
    coupons: Coupon.list,
  })
};

const mapDispatchToProps = dispatch => ({
  fetchCoupon: (params) => dispatch(fetchCoupons(params)),
  deleteCoupon: (prams) => dispatch(deleteCoupon(prams)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Coupons));
