import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import Dropzone from "react-dropzone"
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from "yup";
import Switch from "react-switch"
import { createCategory, fetchSpecificCategorys, updateCategory } from '../../store/actions'
import { message, Spin } from 'antd'
import service from 'helpers/Api/FetchInterceptor';

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      No
    </div>
  )
}

const OnSymbol = props => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      Yes
    </div>
  )
}

const MultiSelect = ({
  field,
  form,
  options,
  isMulti = false,
  placeholder = 'Select'
}) => {
  function onChange(option) {
    form.setFieldValue(
      field.name,
      option ? (option).map((item) => item.value) : [],
    );
  }

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option) => field.value?.indexOf(option.value) >= 0)
        : options.find((option) => option.value === field.value);
    } else {
      return isMulti ? [] : ('');
    }
  };

  if (!isMulti) {
    return (
      <Select
        options={options}
        name={field.name}
        value={options ? options.find(option => option.value === field.value) : ''}
        onChange={(option) => form.setFieldValue(field.name, option.value)}
        onBlur={field.onBlur}
        placeholder={placeholder}
      />
    )
  } else {
    return (
      <Select
        className="react-select-container"
        classNamePrefix="react-select"
        name={field.name}
        value={getValue()}
        onChange={onChange}
        options={options}
        isMulti={true}
        placeholder={placeholder}
      />
    )
  }
}

export class CategoryOperations extends Component {
  static propTypes = {
    type: PropTypes.any
  }


  constructor(props) {
    super(props)
    this.state = {
      selectedFiles: [],
      isBrandsLoading: true,
      allBrands: [],
      isSubmiting: false
    }
  }


  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    )

    this.setState({ selectedFiles: files })
  }

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  componentDidMount() {
    const { match } = this.props;
    if (this.props.type == "EDIT") {
      console.log('Getting ID', match.params.id)
      this.props.fetchSpecificCategorys(match.params.id)
    }
    // this.setState()
    const that = this;
    service.get("/brands?allPage=1").then(res => {
      console.log(res.data, "=============")
      that.setState({
        allBrands: res.data,
        isBrandsLoading: false
      })
    })
    // return result.data;
  }



  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Categorys" breadcrumbItem={`${this.props.type == "ADD" ? 'Add' : 'Update'} Category`} />

            <Row>
              <Col xs="12">
                <Spin spinning={this.props.isLoading}>
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      ...(this.props.type == "EDIT" ?
                        {
                          ...this.props.category,
                          selectedBrands: this.props?.category?.brands?.map(i => i.id) || [],
                          main_data: this.props?.category?.product_attribute?.map(i => ({
                            id: i.id,
                            name: i.attribute_name,
                            question: i.que,
                            priority: i.priority,
                            is_delete: 0,
                            is_visible_on_product_detail: i.is_visible_on_product_detail,
                            options: i.sub_attribute.map(j => ({
                              id: j.id,
                              is_delete: 0,
                              is_visible_on_product_detail: 1,
                              name: j.name_value,
                              priority: j.priority,
                            }))
                          }))
                        } : {
                          main_data: []
                        })
                    }}
                    validationSchema={
                      Yup.object().shape({
                        name: Yup.string().required('Name is required'),
                        description: Yup.string().required('Description is required'),
                        main_data: Yup.array().of(
                          Yup.object().shape({
                            name: Yup.string().required("Name required"),
                            priority: Yup.string().required("Priority required"),
                            question: Yup.string().required("Question required"),
                            options: Yup.array().of(
                              Yup.object().shape({
                                name: Yup.string().required("Name required"),
                                priority: Yup.string().required("Priority required"),
                              })
                            )
                          })
                        )
                        // icon: Yup.mixed().required('Icon is required')
                      })}
                    onSubmit={values => {
                      const that = this;
                      console.log(values, "==========================SUBMIT==========================")
                      // return;
                      this.setState({ isSubmiting: true })
                      values.is_active = values.is_active ? 1 : 0;
                      let apiSubmit = null;
                      values.brand_ids = values.selectedBrands
                      if (this.props.type == "EDIT") {
                        if (!(values.icon instanceof File)) {
                          delete values['icon']
                        }
                        values._method = 'patch'
                        this.props.updateCategory([this.props.match.params.id, values])
                      } else {
                        this.props.createCategory(values)
                      }
                    }}
                  >
                    {({ errors, status, touched, setFieldValue, validateForm, values, ...formik }) => (
                      <>
                        <Card>
                          <CardBody>
                            <CardTitle className="h4">Basic Information</CardTitle>
                            <Form className="form-horizontal">
                              <Row>

                                <Col sm="6">
                                  <div className="mb-3">
                                    <Label for="name" className="form-label">
                                      Category Name *
                                    </Label>
                                    <Field
                                      name="name"
                                      type="text"
                                      className={
                                        "form-control" +
                                        (errors.name
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage name="name" render={msg => <div className="error">{msg}</div>} />
                                  </div>
                                </Col>
                                <Col sm="6">
                                  <div className="mb-3">
                                    <Label for="text" className="form-label">
                                      Category description *
                                    </Label>
                                    <Field
                                      name="description"
                                      type="text"
                                      className={
                                        "form-control" +
                                        (errors.description
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage name="description" render={msg => <div className="error">{msg}</div>} />
                                  </div>
                                </Col>
                                <Col sm="6">
                                  <div className="mb-3">
                                    <Label className='pr-3'>Category Status</Label> <br />
                                    <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      className="me-1 mb-sm-8 mb-2"
                                      checkedIcon={<OnSymbol />}
                                      onColor="#626ed4"
                                      onChange={() =>
                                        setFieldValue('is_active', values.is_active ? !true : !false)
                                      }
                                      checked={values.is_active ? true : false}
                                    />
                                  </div>
                                </Col>
                                <Col sm="12">
                                  <div className="mb-3">
                                    <Label className='pr-3'>Brands</Label> <br />
                                    <Field
                                      name="selectedBrands"
                                      placeholder="Select Brands"
                                      isMulti={true}
                                      component={MultiSelect}
                                      options={this.state?.allBrands?.map(i => ({
                                        label: i.name,
                                        value: i.id
                                      }))}
                                    />
                                    {console.log("This state", this.state)}
                                  </div>
                                </Col>
                              </Row>

                              <div className="d-flex flex-wrap gap-2 mt-4">
                                <Button
                                  type="submit"
                                  color="primary"
                                  disabled={this.props.isLoading}
                                >

                                  {this.props.isLoading ? ' Saving....' : 'Save Changes'}
                                </Button>
                              </div>
                            </Form>

                          </CardBody>
                        </Card>

                        <Card>
                          <CardBody>
                            <CardTitle className="h4">Categories attributes</CardTitle>

                            {values?.main_data?.map((field, index) => (
                              <>
                                <div key={index} className='border-bottom mt-3'>
                                  {!values.main_data?.[`${index}`]?.is_delete && (
                                    <Card>
                                      <Row>
                                        <Col sm="6">
                                          <div className="mb-3">
                                            <Label for={`main_data[${index}].name`} className="form-label">
                                              Attribute Name *
                                            </Label>
                                            <Field
                                              name={`main_data[${index}].name`}
                                              type="text"
                                              className={
                                                "form-control" +
                                                (errors.main_data?.[`${index}`]?.name
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                            />
                                            <ErrorMessage name={`main_data[${index}].name`} render={msg => <div className="error">{msg}</div>} />
                                          </div>
                                        </Col>
                                        <Col sm="6">
                                          <div className="mb-3">
                                            <Label for={`main_data[${index}].question`} className="form-label">
                                              Attribute Question *
                                            </Label>
                                            <Field
                                              name={`main_data[${index}].question`}
                                              type="text"
                                              className={
                                                "form-control" +
                                                (errors.main_data?.[`${index}`]?.question
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                            />
                                            <ErrorMessage name={`main_data[${index}].question`} render={msg => <div className="error">{msg}</div>} />
                                          </div>
                                        </Col>
                                        <Col sm="6">
                                          <div className="mb-3">
                                            <Label for={`main_data[${index}].priority`} className="form-label">
                                              Attribute Priority *
                                            </Label>
                                            <Field
                                              name={`main_data[${index}].priority`}
                                              type="number"
                                              className={
                                                "form-control" +
                                                (errors.main_data?.[`${index}`]?.priority
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                            />
                                            <ErrorMessage name={`main_data[${index}].priority`} render={msg => <div className="error">{msg}</div>} />
                                          </div>
                                        </Col>
                                        <Col sm="6">
                                          <div className="mb-3">
                                            <Label className='pr-3'>Visible on product page</Label> <br />
                                            <Switch
                                              uncheckedIcon={<Offsymbol />}
                                              className="me-1 mb-sm-8 mb-2"
                                              checkedIcon={<OnSymbol />}
                                              onColor="#626ed4"
                                              onChange={() =>
                                                setFieldValue(`main_data[${index}].is_visible_on_product_detail`, values.main_data?.[`${index}`]?.is_visible_on_product_detail ? !true : !false)
                                              }
                                              checked={values.main_data?.[`${index}`]?.is_visible_on_product_detail ? true : false}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="12" className='mb-3'>
                                          <h5>Categories options</h5>
                                          {values?.main_data?.[`${index}`]?.options?.map((field, indexOfOption) => (
                                            <>
                                              {!values.main_data[`${index}`]?.options?.[`${indexOfOption}`]?.is_delete && (
                                                <Row key={indexOfOption}>
                                                  <Col sm="7">
                                                    <div className="mb-3">
                                                      <Label for={`main_data[${index}].options.[${indexOfOption}].name`} className="form-label">
                                                        Attribute value *
                                                      </Label>
                                                      <Field
                                                        name={`main_data[${index}].options.[${indexOfOption}].name`}
                                                        type="text"
                                                        className={
                                                          "form-control" +
                                                          (errors.main_data?.[`${index}`]?.options?.[`${indexOfOption}`]?.name
                                                            ? " is-invalid"
                                                            : "")
                                                        }
                                                      />
                                                      <ErrorMessage name={`main_data[${index}].options.[${indexOfOption}].name`} render={msg => <div className="error">{msg}</div>} />
                                                    </div>
                                                  </Col>
                                                  <Col sm="3">
                                                    <div className="mb-3">
                                                      <Label for={`main_data[${index}].options.[${indexOfOption}].priority`} className="form-label">
                                                        Attribute Priority *
                                                      </Label>
                                                      <Field
                                                        name={`main_data[${index}].options.[${indexOfOption}].priority`}
                                                        type="number"
                                                        className={
                                                          "form-control" +
                                                          (errors.main_data?.[`${index}`]?.options?.[`${indexOfOption}`]?.priority
                                                            ? " is-invalid"
                                                            : "")
                                                        }
                                                      />
                                                      <ErrorMessage name={`main_data[${index}].options.[${indexOfOption}].priority`} render={msg => <div className="error">{msg}</div>} />
                                                    </div>
                                                  </Col>
                                                  <Col sm="2" className='text-center'>
                                                    <Button
                                                      color="danger"
                                                      style={{
                                                        marginTop: "28px"
                                                      }}
                                                      onClick={() => setFieldValue(`main_data[${index}].options`, values.main_data?.[`${index}`].options?.map((val, i1) => i1 !== indexOfOption ? val : { ...val, is_delete: 1 }))}
                                                    // disabled={this.props.isLoading}
                                                    // onClick={() => setFieldValue(`main_data[${index}].options`, [...values.main_data?.[`${index}`].options, { name: "", priority: 0 }])}
                                                    >

                                                      Remove
                                                    </Button>
                                                  </Col>
                                                </Row>
                                              )}
                                            </>
                                          ))}
                                          <Button
                                            color="primary"
                                            // disabled={this.props.isLoading}
                                            onClick={() => setFieldValue(`main_data[${index}].options`, [...values.main_data?.[`${index}`].options, { name: "", priority: 0, is_delete: 0, is_visible_on_product_detail: 1}])}
                                          >

                                            Add Options
                                          </Button>
                                        </Col>
                                      </Row>
                                      <button type="button" onClick={() => setFieldValue('main_data', values.main_data.map((val, i) => i !== index ? val : { ...val, is_delete: 1 }))}>
                                        Remove
                                      </button>
                                    </Card>
                                  )}

                                  {/* Add more input main_data for other properties */}
                                </div>
                              </>
                            ))}

                            {/* Button to add a new field */}
                            {/* <Button
                                  type="submit"
                                  color="primary"
                                  disabled={this.props.isLoading}
                                >

                                  {this.props.isLoading ? ' Saving....' : 'Save Changes'}
                                </Button> */}
                            <Button color="secondary" onClick={() => setFieldValue('main_data', [...values.main_data, { name: '', qustion: '', priority: 0, is_visible_on_product_detail: 1, options: [], is_delete: 0 }])}>
                              Add New Category
                            </Button>

                          </CardBody>
                        </Card>
                      </>
                    )}
                  </Formik>

                </Spin>

              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment >
    )
  }
}



const mapStateToProps = (state) => ({
  category: state.Category.single,
  isLoading: state.Category.isLoading
})

const mapDispatchToProps = dispatch => ({
  createCategory: payload => dispatch(createCategory(payload)),
  updateCategory: (payload) => dispatch(updateCategory(payload)),
  fetchSpecificCategorys: payload => dispatch(fetchSpecificCategorys(payload))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CategoryOperations))

