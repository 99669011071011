import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import classNames from "classnames";

// Import FecthAPI
import service from "../../helpers/Api/FetchInterceptor";
import Lightbox from "react-image-lightbox";
import { deleteNewuser, fetchNewusers } from "../../store/actions";
import PaginateTableSaga from "../../components/UI/Table/TableSaga";
import { Popconfirm, Tag, Table, Dropdown } from "antd";
import { MoreOutlined, EditOutlined, DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons"
import moment from "moment";

function Test(props) {
  const [visible, setVisible] = useState(false)
  return (
    <React.Fragment>
      <img src={props.row.logo} style={{ width: '200px' }} onClick={() => setVisible(true)} />
      {visible && (
        <Lightbox
          mainSrc={props.row.logo}
          enableZoom={false}
          imageCaption={props.row.company_name}
          onCloseRequest={() => { setVisible(false) }}
        />
      )}
    </React.Fragment>
  )
}

class Newusers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customQuery: {}
    };

    this.tableColumns = [
      { 
        title: "ID",
        dataIndex: "id",
        sorter: true,
      },
      { 
        title: "Profile",
        dataIndex: "name",
        sorter: true,
        render: (_, row) => {
          return (
            <>
            <div className="profile">
              <div className="profile-image">
                <img src={row.profile} />
              </div>
              <div className="profile-info">
                <p className="mb-0">{row.name}</p>
                <h6>{row.email}</h6>
              </div>
            </div>
            </>
          )
        }
      },
      { 
        title: "Phone",
        dataIndex: "phone",
        sorter: true,
      },
      { 
        title: "User Type",
        dataIndex: "type",
        sorter: true,
      },
      {
        title: "Status",
        dataIndex: "is_active",
        render: (text, row) => {
          return (
            <>
              {row.is_active == 1 ? (
                <Tag color={"green"}>Yes</Tag>
              ) : (
                <Tag color={"red"}>No</Tag>
              )}
            </>
          );
        },
        sorter: true,
      },
      {
        title: "Created Date",
        dataIndex: "created_at",
        render: (text, row) => {
          return (
            <>
              <Tag>
                {moment(row.created_at).format('YYYY-MM-DD')}
              </Tag>
            </>
          );
        },
        sorter: true,
      },
      {
        title: "Action",
        dataIndex: "",
        sorter: false,
        render: (_, row) => (
          <>
            <div className="d-flex gap-3">
              <Link className="text-success" to={`/users/${row.id}/edit`}>
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                // onClick={() => this.handleUserClick(user)}
                ></i>
              </Link>
              <Popconfirm
                title="Are you sure?"
                className="text-danger cursor-pointer"
                description="You want to Delete"
                onConfirm={() => this.props.deleteNewuser(row.id).payload}
                onOpenChange={() => console.log('open change')}
              >
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                // onClick={() => this.onClickDelete(user)}
                ></i>
              </Popconfirm>
            </div>
          </>
        )
      },
    ];

  }



  async fetchApi(params) {
    let result = await service.get('/newusers' + "?" + params);
    return result.data;
  }

  componentDidMount() {
    // this.props.fetchNewuser("");
  }

  componentDidUpdate(prevProps) {
  }

  render() {
    //meta title
    document.title = "User";

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("Dashboards")}
              breadcrumbItem={this.props.t("User")}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row className="mb-4">
                      <Col sm="4">
                      </Col>
                      <Col sm="8">
                        <div className="text-sm-end">
                          <Link to={'/users/add'}>
                            <Button
                              color="primary"
                              className="font-16 btn-block btn btn-primary"
                            >
                              <i className="mdi mdi-plus-circle-outline me-1" />
                              Create New User
                            </Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                    <PaginateTableSaga
                      fetch={this.props.fetchNewuser}
                      isLoading={this.props.newuserState.isLoading}
                      columns={this.tableColumns}
                      list={this.props.newusers}
                      pagination={this.props.newuserState.pagination}
                      customeQuery={this.customQuery}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* Render Breadcrumb */}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Newusers.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = ({ Newuser }) => {
  return ({
    newuserState: Newuser,
    newusers: Newuser.list,
  })
};

const mapDispatchToProps = dispatch => ({
  fetchNewuser: (params) => dispatch(fetchNewusers(params)),
  deleteNewuser: (prams) => dispatch(deleteNewuser(prams)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Newusers));
