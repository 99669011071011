import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap"
import Select from "react-select"
import Dropzone from "react-dropzone"
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from "yup";
import Switch from "react-switch"
import { createProduct, createProductImage, deleteProductImage, fetchBrands, fetchCategorys, fetchSpecificProducts, updateProduct } from '../../store/actions'
import { message, Popconfirm, Spin } from 'antd'
import AddImage from './Componet/AddImage';
import ProductForm from './Componet/SearchCompactiable';

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      No
    </div>
  )
}

const OnSymbol = props => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      Yes
    </div>
  )
}

let oldFormValue = undefined;

export class ProductOperations extends Component {
  static propTypes = {
    type: PropTypes.any
  }


  constructor(props) {
    super(props)
    this.state = {
      selectedFiles: [],
      isSubmiting: false,
      showModalOfAddImage: false,
      formikRef: React.createRef(),
      formValue: {}
    }
    // this.
    this.handleNewImage = this.handleNewImage.bind(this);
    this.toggleModalOfAddImage = this.toggleModalOfAddImage.bind(this);
  }

  toggleModalOfAddImage() {
    this.setState((prevState) => ({
      showModalOfAddImage: !prevState.showModalOfAddImage
    }))
  }


  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    )

    this.setState({ selectedFiles: files })
  }

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  async componentDidMount() {
    const { match } = this.props;
    if (this.props.type == "EDIT") {
      console.log('Getting ID', match.params.id)
      console.log("CALLED BEFORE")
      await this.props.fetchSpecificProducts(match.params.id)
      console.log("CALLED AFTER")
    }
    this.props.fetchCategory("perPage=-1")
    this.props.fetchBrand("perPage=-1")
  }

  componentDidUpdate(PrevProps, prevState) {
    console.log("Ref cahnges", this.state.formikRef.current)
    if (this.state.formikRef.current?.values?.category_id !== prevState.formikRef.current?.values?.category_id) {
      console.log("Category changed ===================================>")
    }
  }

  handleNewImage(values) {
    const { match } = this.props;
    this.props.createProductImage({
      ...values,
      product_id: match.params.id
    })
  }

  handleDeleteImageFile(id) {
    this.props.deleteProductImage(id);
  }

  changeInCategory(catId) {
    console.log("Category Change================>")
    const selectedCat = this.props?.categorys?.find(i => i.id == catId)
    // console.log(this.state.formikRef)
    this.state?.formikRef?.current?.setFieldValue("attributes", selectedCat?.product_attribute?.map(i => "") || [])
    // console.log(selectedCat, "====SELECTED CAT====")
  }

  selectedAttribute(assignAttributes = [], attributes = []) {
    console.log(assignAttributes, attributes)
    const resultArray = attributes.map(attr => {
      const posibleAttrubutes = attr.sub_attribute.map(i => i.id)
      for (let index in posibleAttrubutes) {
        const subAttributeId = posibleAttrubutes[index]
        if (assignAttributes.includes(subAttributeId)) {
          return `${subAttributeId}`
        }
      }
      return ""
      // return assignAttributes.includes(attr.id) ? att 
    })
    console.log(resultArray, "RESULT ARRAy")
    return resultArray
  }



  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Products" breadcrumbItem={`${this.props.type == "ADD" ? 'Add' : 'Update'} Product`} />
            {(this.props?.product?.id || this.props.type == "ADD") && (
              <Row>
                <Col xs="12">
                  <Spin spinning={this.props.isLoading}>
                    <Formik
                      innerRef={this.state.formikRef}
                      enableReinitialize={true}
                      initialValues={{
                        ...(this.props.type == "EDIT" ? {
                          ...this?.props?.product,
                          ...(() => {
                            let returnObject = {};
                            console.log(this?.props?.product, "Xxxxxxxxx");
                            if (this?.props?.product?.relatedTo?.id) {
                              returnObject.relativeProduct = { value: this?.props?.product?.relatedTo?.id || "", label: this?.props?.product?.relatedTo?.name || "" }
                            }
                            if (this?.props?.product?.parentProduct?.id) {
                              returnObject.parentProduct = { value: this?.props?.product?.parentProduct?.id || "", label: this?.props?.product?.parentProduct?.name || "" }
                            }
                            return returnObject;
                            // if()
                          })(),
                          // 'relativeProduct': { value: this?.props?.product?.relatedTo?.id || "", label: this?.props?.product?.relatedTo?.name || "" },
                          // 'parentProduct': { value: this?.props?.product?.parentProduct?.id || "", label: this?.props?.product?.parentProduct?.name || "" },
                          attributes: this.selectedAttribute(this.props?.product?.assigned_attributes?.map(i => i.sub_attribute_id), this.props?.product.category?.product_attribute)
                        } : {})
                      }}
                      validationSchema={
                        Yup.object().shape({
                          name: Yup.string().required('Name is required'),
                          slug: Yup.string().required('Slug is required'),
                          thumbnail: this.props.type == "EDIT" ? Yup.mixed() : Yup.string().required('Thumbnail is required'),
                          b_to_c_price: Yup.number().required('b to c is required'),
                          b_to_b_l1_price: Yup.number().required('b to b level 1 is required'),
                          b_to_b_l2_price: Yup.number().required('b to b level 2 is required'),
                          b_to_b_l3_price: Yup.number().required('b to b level 3 is required'),
                          sort_description: Yup.string().required('Sort description is required'),
                          long_description: Yup.string().required('Long description is required'),
                          slug: Yup.string().required('Slug is required'),
                          zoho_id: Yup.number().required('Zoho id is required'),
                          product_code: Yup.string().required('Product Code is required'),
                          category_id: Yup.string().required('Please select category'),
                          brand_id: Yup.string().required('Product select brand'),
                          attributes: Yup.array().of(
                            Yup.string().required("Please select attribute")
                          )
                          // description: Yup.string().required('Description is required'),
                          // thumbnail: Yup.mixed().required('Icon is required')
                        })}
                      onSubmit={values => {
                        const that = this;
                        // return;
                        this.setState({ isSubmiting: true })

                        values.is_active = values.is_active ? 1 : 0;
                        values.is_featured = values.is_featured ? 1 : 0;
                        values.is_compactable = values.is_compactable ? 1 : 0;
                        // values.sub_attribute_ids = ;

                        values.attributes.forEach((val, key) => {
                          values[`sub_attribute_ids[${key}]`] = val
                        })

                        if (values?.parentProduct?.value) {
                          values.parent_id = values.parentProduct?.value
                        } else {
                          values.parent_id = 0
                        }

                        if (values?.relativeProduct?.value) {
                          values.relative_product_id = values.relativeProduct?.value
                        } else {
                          values.relative_product_id = 0
                        }
                        // if(values.attributes)
                        let apiSubmit = null;
                        if (this.props.type == "EDIT") {
                          if (!(values.thumbnail instanceof File)) {
                            delete values['thumbnail']
                          }
                          values._method = 'patch'
                          this.props.updateProduct([this.props.match.params.id, values])
                        } else {
                          this.props.createProduct(values)
                        }
                      }}
                    >
                      {({ errors, status, touched, setFieldValue, validateForm, values }) => (
                        <>
                          {console.log(values, "Field values")}
                          {/* {this.setState({formValue: values})} */}
                          {/* {console.log(oldFormValue, values.category_id)} */}
                          {oldFormValue != values.category_id ? this.changeInCategory(values.category_id) : ""}

                          {(() => {
                            oldFormValue = values.category_id
                          })()}
                          <Card>
                            <CardBody>
                              <CardTitle className="h4">Product Attributes</CardTitle>
                              <Row>
                                <Col sm="6">
                                  <div className="mb-3">
                                    <Label for="text" className="form-label">
                                      Product Categories*
                                    </Label>
                                    <Field component="select" name="category_id" onChange={(e) => {
                                      console.log("Change in UI")
                                      setFieldValue("category_id", e.target.value)
                                      // changeTheCategory()
                                    }} className={
                                      "form-control" +
                                      (errors.category_id
                                        ? " is-invalid"
                                        : "")
                                    }>
                                      <option value={""}>
                                        Select Category
                                      </option>
                                      {this.props.categorys?.map(category => (
                                        <option key={category.id} value={category.id}>
                                          {category.name}
                                        </option>
                                      ))}
                                    </Field>

                                    <ErrorMessage name="category_id" render={msg => <div className="error">{msg}</div>} />
                                  </div>
                                </Col>
                                {values.category_id && (
                                  <Col sm="12">

                                    {
                                      [this.props?.categorys?.find(i => i.id == values.category_id) || []].map((category) => (
                                        <div key={category.id}>

                                          {category?.product_attribute?.map((attribute, indexOfAttribute) => (
                                            <Row key={attribute.id}>
                                              <Col sm="2">
                                                {attribute.attribute_name}
                                              </Col>
                                              <Col sm="10" className='d-flex' style={{ flexWrap: "wrap" }}>
                                                {attribute.sub_attribute.map((subAttribute, indexOfSubAttribute) => (
                                                  <div key={subAttribute.id} className='d-flex' style={{ minWidth: "40px" }}>
                                                    <Field
                                                      name={`attributes[${indexOfAttribute}]`}
                                                      id={`attributes[${indexOfAttribute}].sub_attribute[${indexOfSubAttribute}]`}
                                                      value={`${subAttribute?.id}`}
                                                      type="radio"
                                                      className={
                                                        "" +
                                                        (errors?.attributes?.[`${indexOfAttribute}`]
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                    />
                                                    <label style={{ whiteSpace: "nowrap", marginRight: "10px", marginTop: "6px", marginLeft: "4px" }} htmlFor={`attributes[${indexOfAttribute}].sub_attribute[${indexOfSubAttribute}]`}>{subAttribute.name_value}</label>
                                                  </div>
                                                ))}
                                                <ErrorMessage name={`attributes[${indexOfAttribute}]`} render={msg => <div className="error"> {msg}</div>} />
                                              </Col>
                                            </Row>
                                          ))}
                                        </div>
                                      ))
                                    }
                                    <Row>

                                    </Row>
                                  </Col>
                                )}
                              </Row>
                            </CardBody>
                          </Card>
                          <Card>
                            <CardBody>
                              <CardTitle className="h4">Product Associates</CardTitle>
                              <Row className='mt-3'>
                                <Col sm="12" className='mb-3'>
                                  <ProductForm values={values} setFieldValue={setFieldValue} label='Select Parent Product:' formikKeyName='parentProduct' />
                                </Col>
                                <Col sm="6">
                                  <ProductForm values={values} setFieldValue={setFieldValue} label='Select Related Product:' formikKeyName='relativeProduct' />
                                </Col>
                                <Col sm="6">

                                  <div className="mb-3">
                                    <Label className='pr-3'>Compactible</Label> <br />
                                    <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      className="me-1 mb-sm-8 mb-2"
                                      checkedIcon={<OnSymbol />}
                                      onColor="#626ed4"
                                      onChange={() =>
                                        setFieldValue('is_compactable', values.is_compactable ? !true : !false)
                                      }
                                      checked={values.is_compactable ? true : false}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                          <Card>
                            <CardBody>
                              <CardTitle className="h4">Basic Information</CardTitle>
                              <Form className="form-horizontal">
                                <Row>

                                  <Col sm="6">
                                    <div className="mb-3">
                                      <Label for="name" className="form-label">
                                        Product Name *
                                      </Label>
                                      <Field
                                        name="name"
                                        type="text"
                                        className={
                                          "form-control" +
                                          (errors.name
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage name="name" render={msg => <div className="error">{msg}</div>} />
                                    </div>
                                  </Col>
                                  <Col sm="6">
                                    <div className="mb-3">
                                      <Label for="text" className="form-label">
                                        Product code *
                                      </Label>
                                      <Field
                                        name="product_code"
                                        type="text"
                                        className={
                                          "form-control" +
                                          (errors.product_code
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage name="product_code" render={msg => <div className="error">{msg}</div>} />
                                    </div>
                                  </Col>

                                  <Col sm="6">
                                    <div className="mb-3">
                                      <Label for="text" className="form-label">
                                        Product Brand*
                                      </Label>
                                      <Field component="select" name="brand_id" className={
                                        "form-control" +
                                        (errors.brand_id
                                          ? " is-invalid"
                                          : "")
                                      }>
                                        <option value={""}>
                                          Select Brand
                                        </option>
                                        {this.props.brands?.map(brand => (
                                          <option key={brand.id} value={brand.id}>
                                            {brand.name}
                                          </option>
                                        ))}
                                      </Field>

                                      <ErrorMessage name="brand_id" render={msg => <div className="error">{msg}</div>} />
                                    </div>
                                  </Col>
                                  <Col sm="6">
                                    <div className="mb-3">
                                      <Label for="text" className="form-label">
                                        Product slug *
                                      </Label>
                                      <Field
                                        name="slug"
                                        type="text"
                                        className={
                                          "form-control" +
                                          (errors.slug
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage name="slug" render={msg => <div className="error">{msg}</div>} />
                                    </div>
                                  </Col>
                                  <Col sm="6">
                                    <div className="mb-3">
                                      <Label for="text" className="form-label">
                                        Product sort description *
                                      </Label>
                                      <Field
                                        name="sort_description"
                                        type="text"
                                        className={
                                          "form-control" +
                                          (errors.sort_description
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage name="sort_description" render={msg => <div className="error">{msg}</div>} />
                                    </div>
                                  </Col>
                                  <Col sm="6">
                                    <div className="mb-3">
                                      <Label for="text" className="form-label">
                                        B To B Level 1 Price *
                                      </Label>
                                      <Field
                                        name="b_to_b_l1_price"
                                        type="number"
                                        className={
                                          "form-control" +
                                          (errors.b_to_b_l1_price
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage name="b_to_b_l1_price" render={msg => <div className="error">{msg}</div>} />
                                    </div>
                                  </Col>
                                  <Col sm="6">
                                    <div className="mb-3">
                                      <Label for="text" className="form-label">
                                        B To B Level 2 Price *
                                      </Label>
                                      <Field
                                        name="b_to_b_l2_price"
                                        type="number"
                                        className={
                                          "form-control" +
                                          (errors.b_to_b_l2_price
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage name="b_to_b_l2_price" render={msg => <div className="error">{msg}</div>} />
                                    </div>
                                  </Col>
                                  <Col sm="6">
                                    <div className="mb-3">
                                      <Label for="text" className="form-label">
                                        B To B Level 3 Price *
                                      </Label>
                                      <Field
                                        name="b_to_b_l3_price"
                                        type="number"
                                        className={
                                          "form-control" +
                                          (errors.b_to_b_l3_price
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage name="b_to_b_l3_price" render={msg => <div className="error">{msg}</div>} />
                                    </div>
                                  </Col>
                                  <Col sm="6">
                                    <div className="mb-3">
                                      <Label for="text" className="form-label">
                                        B To C Price *
                                      </Label>
                                      <Field
                                        name="b_to_c_price"
                                        type="number"
                                        className={
                                          "form-control" +
                                          (errors.b_to_c_price
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage name="b_to_c_price" render={msg => <div className="error">{msg}</div>} />
                                    </div>
                                  </Col>

                                  <Col sm="6">
                                    <div className="mb-3">
                                      <Label for="text" className="form-label">
                                        Zoho ID *
                                      </Label>
                                      <Field
                                        name="zoho_id"
                                        type="text"
                                        className={
                                          "form-control" +
                                          (errors.zoho_id
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage name="zoho_id" render={msg => <div className="error">{msg}</div>} />
                                    </div>
                                  </Col>
                                  {/* <Col sm="6">
                                  <div className="mb-3">
                                    <Label for="text" className="form-label">
                                      B To B Price *
                                    </Label>
                                    <Field
                                      name="b_to_b_price"
                                      type="number"
                                      className={
                                        "form-control" +
                                        (errors.b_to_b_price
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage name="b_to_b_price" render={msg => <div className="error">{msg}</div>} />
                                  </div>
                                </Col> */}
                                  <Col sm="6">
                                    <div className="mb-3">
                                      <Label className='pr-3'>Product Featured</Label> <br />
                                      <Switch
                                        uncheckedIcon={<Offsymbol />}
                                        className="me-1 mb-sm-8 mb-2"
                                        checkedIcon={<OnSymbol />}
                                        onColor="#626ed4"
                                        onChange={() =>
                                          setFieldValue('is_featured', values.is_featured ? !true : !false)
                                        }
                                        checked={values.is_featured ? true : false}
                                      />
                                    </div>
                                  </Col>
                                  <Col sm="6">
                                    <div className="mb-3">
                                      <Label className='pr-3'>Product Status</Label> <br />
                                      <Switch
                                        uncheckedIcon={<Offsymbol />}
                                        className="me-1 mb-sm-8 mb-2"
                                        checkedIcon={<OnSymbol />}
                                        onColor="#626ed4"
                                        onChange={() =>
                                          setFieldValue('is_active', values.is_active ? !true : !false)
                                        }
                                        checked={values.is_active ? true : false}
                                      />
                                    </div>
                                  </Col>

                                  <Col sm="6">
                                    <div className="mb-3">
                                      <div>
                                        <Label for="text" className="form-label">
                                          Icon *
                                        </Label>
                                        {/* <Field type="file" className={
                                      "form-control" +
                                      (errors.thumbnail
                                        ? " is-invalid"
                                        : "")
                                    }
                                    // onChange={(event) => {
                                    //   setFieldValue("thumbnail", event.currentTarget.files[0]);
                                    // }}
                                    /> */}
                                        <input type="file" className={
                                          "form-control" +
                                          (errors.thumbnail
                                            ? " is-invalid"
                                            : "")
                                        } onChange={(event) => {
                                          setFieldValue("thumbnail", event.currentTarget.files[0]);
                                        }} />
                                        <ErrorMessage name="thumbnail" render={msg => <div className="error">{msg}</div>} />
                                      </div>
                                    </div>
                                    {
                                      !(values.image instanceof File) ? (<>
                                        <img style={{
                                          width: "200px"
                                        }} src={values.thumbnail} alt="" />
                                      </>) : (<></>)
                                    }
                                  </Col>
                                  <Col sm="6">
                                    <div className="mb-3">
                                      <Label for="text" className="form-label">
                                        Product long description *
                                      </Label>
                                      <Field
                                        name="long_description"
                                        component="textarea"
                                        rows={5}
                                        className={
                                          "form-control" +
                                          (errors.long_description
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage name="long_description" render={msg => <div className="error">{msg}</div>} />
                                    </div>
                                  </Col>
                                </Row>


                                <div className="d-flex flex-wrap gap-2 mt-4">
                                  <Button
                                    type="submit"
                                    color="primary"
                                    disabled={this.props.isLoading}
                                  >

                                    {this.props.isLoading ? ' Saving....' : 'Save Changes'}
                                  </Button>
                                </div>
                              </Form>

                            </CardBody>
                          </Card>
                        </>
                      )}
                    </Formik>
                  </Spin>

                </Col>

                {this.props.type == "EDIT" && (
                  <Col xs="12">
                    <Spin spinning={this.props.isLoading}>
                      <Card>
                        <CardBody>
                          <AddImage show={this.state.showModalOfAddImage} handleClose={this.toggleModalOfAddImage} handleFormSubmit={this.handleNewImage} />
                          <Row className="mb-4">
                            <Col sm="4">
                              <CardTitle className="h4 mb-4">Product Images</CardTitle>
                            </Col>
                            <Col sm="8">
                              <div className="text-sm-end">

                                <Button
                                  color="success"
                                  className="font-16 btn-block btn btn-success"
                                  onClick={this.toggleModalOfAddImage}
                                >
                                  <i className="mdi mdi-plus-circle-outline me-1" />
                                  Add Image
                                </Button>
                              </div>
                            </Col>
                          </Row>
                          <div className="table-responsive">
                            <Table className="table align-middle mb-0 table-nowrap">
                              <thead className="thead-light">
                                <tr>
                                  <th>Product Image</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              {this.props?.product?.images?.length ? (
                                <>
                                  {this.props?.product?.images.map(img => (
                                    <tr key={img.id}>
                                      <td>
                                        <img
                                          src={img.image}
                                          style={{
                                            width: "300px",

                                          }}
                                          alt="product-img"
                                          title="product-img"
                                        />
                                      </td>
                                      <td>
                                        <div className="d-flex">
                                          {/* <div className="text-dark" to={"#"}>
                                        <i
                                          className="mdi mdi-pencil font-size-18 cursor-pointer"
                                          id="edittooltip"
                                        ></i>
                                      </div> */}
                                          <Popconfirm
                                            title="Are you sure?"
                                            className="text-danger cursor-pointer"
                                            description="You want to Delete"
                                            onConfirm={() => this.handleDeleteImageFile(img.id)}
                                            onOpenChange={() => console.log('open change')}
                                          >
                                            <div className="text-danger" to={"#"}>
                                              <i
                                                className="mdi mdi-delete font-size-18 cursor-pointer"
                                                id="edittooltip"
                                              ></i>
                                            </div>
                                          </Popconfirm>

                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </>
                              ) : (
                                <th className='w-100 mt-4 text-center'>
                                  No Images
                                </th>
                              )}
                            </Table>
                          </div>
                        </CardBody>
                      </Card>
                    </Spin>
                  </Col>
                )}

              </Row>
            )}
          </Container>
        </div>
      </React.Fragment >
    )
  }
}

const mapStateToProps = (state) => ({
  product: state.Product.single,
  isLoading: state.Product.isLoading,
  categoryState: state.Category,
  categorys: state.Category.list,
  brandState: state.Brand,
  brands: state.Brand.list,
})

const mapDispatchToProps = dispatch => ({
  createProduct: payload => dispatch(createProduct(payload)),
  updateProduct: (payload) => dispatch(updateProduct(payload)),
  fetchSpecificProducts: payload => dispatch(fetchSpecificProducts(payload)),
  fetchCategory: (params) => dispatch(fetchCategorys(params)),
  fetchBrand: (params) => dispatch(fetchBrands(params)),
  createProductImage: (params) => dispatch(createProductImage(params)),
  deleteProductImage: (params) => dispatch(deleteProductImage(params)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductOperations))

