import PropTypes from "prop-types";
import React, { Component } from "react";

//Simple bar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.refDiv = React.createRef();
  }

  componentDidMount() {
    this.initMenu();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  // componentDidUpdate() {}

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop;
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300;
          }
        }
      }
    }, 300);
  };

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;

    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      this.scrollElement(item);
      return false;
    }
    this.scrollElement(item);
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <SimpleBar className="h-100" ref={this.refDiv}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              {/* <li className="menu-title">{this.props.t("Menu")}</li>
              <li>
                <Link to="/dashboard">
                  <i className="bx bx-home-circle" />
                  <span>{this.props.t("Dashboards")}</span>
                </Link>
              </li> */}

              <li className="menu-title">{this.props.t("Apps")}</li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="fas fa-users"></i>
                  <span>{this.props.t("User")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/users">
                      {this.props.t("Users")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/users/add">
                      {this.props.t("Add User")}
                    </Link>
                  </li>
                </ul>

              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-image" />
                  <span>{this.props.t("Banner")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/banners">
                      {this.props.t("Banners")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/banners/add">
                      {this.props.t("Add Banner")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-image" />
                  <span>{this.props.t("Brand")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/brands">
                      {this.props.t("Brands")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/brands/add">
                      {this.props.t("Add Brand")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="fas fa-th-large" />
                  <span>{this.props.t("Category")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/categories">
                      {this.props.t("Categories")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/categories/add">
                      {this.props.t("Add Category")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="fas fa-th-large" />
                  <span>{this.props.t("Coupon")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/coupon-users">
                      {this.props.t("Coupon User")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/coupon">
                      {this.props.t("Coupon")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/coupon/add">
                      {this.props.t("Add Coupon")}
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  {/* <i className="fa fa-calendar" /> */}
                  <i className="fas fa-box"></i>
                  <span>{this.props.t("Product")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/products">
                      {this.props.t("Products")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/products/add">
                      {this.props.t("Add Product")}
                    </Link>
                  </li>
                </ul>

              </li>
              <li>
                <Link to="/#" className="has-arrow">
                  {/* <i className="fa fa-calendar" /> */}
                  <i className="fa fa-shopping-cart"></i>
                  <span>{this.props.t("Order")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/orders">
                      {this.props.t("Orders")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/orders/create">
                      {this.props.t("Add Order")}
                    </Link>
                  </li>
                </ul>

              </li>
              <li>
                <Link to="/#" className="has-arrow">
                  {/* <i className="fa fa-calendar" /> */}
                  <i className="fa fa-shopping-cart"></i>
                  <span>{this.props.t("Delivery Management")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/pincodeCharges">
                      {this.props.t("Pincode charges")}
                    </Link>
                  </li>
                </ul>

              </li>
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-user" />
                  <span>{this.props.t("Team Member")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/teams">
                      {this.props.t("Team Member")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/teams/add">
                      {this.props.t("Add Team Member")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/#" className="has-arrow">
                  {/* <i className="fa fa-calendar" /> */}
                  <i className="fa fa-users"></i>
                  <span>{this.props.t("Customer Query")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/contact-query">
                      {this.props.t("Customer Query")}
                    </Link>
                  </li>
                </ul>

              </li>


            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    );
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
};

export default withRouter(withTranslation()(SidebarContent));
