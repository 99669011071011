import { Spin } from 'antd';
import Breadcrumbs from 'components/Common/Breadcrumb'
import service from 'helpers/Api/FetchInterceptor';
import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router";
import { Card, CardBody, CardTitle, Container, Row, Table } from 'reactstrap'
import { Link } from "react-router-dom";

// const apiResponseCatch = JSON.parse(localStorage?.getItem("API_RESPONSE_CATCH") || {})

// const apiCache = apiResponseCatch || {};
// const apiResponseCatch = JSON.parse(localStorage?.getItem("API_RESPONSE_CATCH") || {})

const apiCache = {};

// Axios wrapper function with caching
const axiosWithCache = async (url, options = {}) => {
  // Generate a unique key for the cache based on the URL and options
  const cacheKey = `${url}${JSON.stringify(options)}`;

  // Check if the response is already cached
  if (apiCache[cacheKey]) {
    console.log(`Using cached response for ${url}`);
    return Promise.resolve(apiCache[cacheKey]);
  }

  try {
    // Make the API call using Axios
    return service({
      url: url,
      method: "GET"
    })
      .then(res => {
        if (!apiCache[cacheKey]) {
          apiCache[cacheKey] = res;
          localStorage.setItem("API_RESPONSE_CATCH", JSON.stringify(apiCache))
        }
        return Promise.resolve(res)
      })

  } catch (error) {
    // Handle errors here
    console.error(`Error making API call to ${url}: ${error.message}`);
    throw error;
  }
};

function emptyResponse(arr, message = <></>) {
  try {
    if (arr.lenght == 0) {
      return message
    } else {
      return <></>
    }
  } catch (err) {
    return message
  }
}

function ProductAssociated(props) {



  const [product, setProduct] = useState({});
  const [variantProducts, setVariantProducts] = useState([]);
  const [compactiableProducts, setCompactiableProducts] = useState([])
  const [relatedProducts, setRelatedProducts] = useState([])
  const [isProductLoading, setIsProductLoading] = useState(false);


  useEffect(() => {
    setIsProductLoading(true);
    // axiosWithCache("/products/" + props.match.params.id).then((res) => {
    service({
      url: "/products/" + props.match.params.id,
      method: "GET"
    }).then((res) => {
      console.log(res.data)
      setProduct(res.data.product)
      setVariantProducts(res.data.variant_products)
      setCompactiableProducts(res.data.compactable_products)
      console.log(res.data.product.related_products, "==============")
      setRelatedProducts(res.data.product.related_products)
      setIsProductLoading(false);
    }).catch(err => {
      setIsProductLoading(false);
      console.log(err.response);
    })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {console.log(product, variantProducts)}
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Product Associates" breadcrumbItem={`Product Associates`} />
          <Spin spinning={isProductLoading}>
            <Row>
              <Card>
                <CardBody>
                  <CardTitle className="h4">Product</CardTitle>
                  <Table className="table align-middle mb-0">
                    <thead className="thead-light">
                      <tr>
                        <th>Image</th>
                        <th>Product Name</th>
                        <th>Attributes</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr key={product.id}>
                        <td><img src={product.thumbnail} style={{ width: "100px" }} /></td>
                        <td>{product.name}</td>
                        <td>
                          {product?.assigned_attributes?.map(attr => (
                            <div key={attr.id}>
                              <b>{attr.sub_attributes.product_attribute.attribute_name}</b>: {attr.sub_attributes.name_value}
                            </div>
                          ))}
                          <div></div>
                        </td>
                        <td>
                          <Link className="text-danger mr-2" to={`/products/${product.id}/edit`}>
                            <i
                              className="mdi mdi-pencil font-size-18"
                              id="edittooltip"
                            ></i>
                          </Link>
                          <Link className="text-info mr-2" to={`/products/associated/${product.id}`}>
                            <i
                              className="mdi mdi-eye font-size-18"
                              id="edittooltip"
                            ></i>
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                </CardBody>
              </Card>
            </Row>
            <Row>
              <Card>
                <CardBody>
                  <CardTitle className="h4">Attribute wise Product</CardTitle>
                  <Table className="table align-middle mb-0">
                    <thead className="thead-light">
                      <tr>
                        <th>Product Name</th>
                        <th>Attributes</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {variantProducts.map(prd => (
                        <tr key={prd.id}>
                          <td>{prd.name}</td>
                          <td>
                            {prd.assigned_attributes.map(attr => (
                              <div key={attr.id}>
                                <b>{attr.sub_attributes.product_attribute.attribute_name}</b>: {attr.sub_attributes.name_value}
                              </div>
                            ))}
                            <div></div>
                          </td>
                          <td>
                            <Link className="text-danger mr-2" to={`/products/${prd.id}/edit`}>
                              <i
                                className="mdi mdi-pencil font-size-18"
                                id="edittooltip"
                              // onClick={() => this.handleUserClick(user)}
                              ></i>
                            </Link>
                            <Link className="text-info mr-2" to={`/products/associated/${prd.id}`}>
                              <i
                                className="mdi mdi-eye font-size-18"
                                id="edittooltip"
                              ></i>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  {emptyResponse(variantProducts, <div className='text-center mt-3'>Product has not variant products</div>)}
                </CardBody>
              </Card>
            </Row>

            <Row>
              <Card>
                <CardBody>
                  <CardTitle className="h4">Compactiable Product</CardTitle>
                  <Table className="table align-middle mb-0">
                    <thead className="thead-light">
                      <tr>
                        <th>Image</th>
                        <th>Product Name</th>
                        <th>Attributes</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>


                      {compactiableProducts?.map(prd => (
                        <tr key={prd.id}>
                          <td><img src={prd.thumbnail} style={{ width: "100px" }} /></td>
                          <td>{prd.name}</td>
                          <td>
                            {prd.assigned_attributes.map(attr => (
                              <div key={attr.id}>
                                <b>{attr.sub_attributes.product_attribute.attribute_name}</b>: {attr.sub_attributes.name_value}
                              </div>
                            ))}
                            <div></div>
                          </td>
                          <td>
                            <Link className="text-danger mr-2" to={`/products/${prd.id}/edit`}>
                              <i
                                className="mdi mdi-pencil font-size-18"
                                id="edittooltip"
                              // onClick={() => this.handleUserClick(user)}
                              ></i>
                            </Link>
                            <Link className="text-info mr-2" to={`/products/associated/${prd.id}`}>
                              <i
                                className="mdi mdi-eye font-size-18"
                                id="edittooltip"
                              ></i>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {emptyResponse(compactiableProducts, <div className='text-center mt-3'>No Compactiable products</div>)}
                </CardBody>
              </Card>
            </Row>

            <Row>
              <Card>
                <CardBody>
                  <CardTitle className="h4">Related Products</CardTitle>
                  <Table className="table align-middle mb-0">
                    <thead className="thead-light">
                      <tr>
                        <th>Image</th>
                        <th>Related Products</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {relatedProducts?.map(prd => (
                        <tr key={prd.id}>
                          <td><img src={prd.thumbnail} style={{ width: "100px" }} /></td>
                          <td>{prd.name}</td>
                          <td>
                            <Link className="text-danger mr-2" to={`/products/${prd.id}/edit`}>
                              <i
                                className="mdi mdi-pencil font-size-18"
                                id="edittooltip"
                              // onClick={() => this.handleUserClick(user)}
                              ></i>
                            </Link>
                            <Link className="text-info mr-2" to={`/products/associated/${prd.id}`}>
                              <i
                                className="mdi mdi-eye font-size-18"
                                id="edittooltip"
                              ></i>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {emptyResponse(relatedProducts, <div className='text-center mt-3'>No Related products</div>)}
                </CardBody>
              </Card>
            </Row>
          </Spin>
        </Container>
      </div>
    </React.Fragment>
  )
}


export default withRouter(ProductAssociated)