import { createStore, applyMiddleware, compose } from "redux";
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import createSagaMiddleware from "redux-saga";
import thunk from 'redux-thunk';

import rootReducer from "./reducers";
import rootSaga from "./sagas";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const store = createStore(
//   rootReducer,
//   composeEnhancers(applyMiddleware(sagaMiddleware))
// );
// sagaMiddleware.run(rootSaga);

const store = configureStore({ 
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware(), sagaMiddleware]
})
sagaMiddleware.run(rootSaga);

export default store;
