import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { ADD_SPONSER, SPONSER_TEST, DELETE_SPONSER, GET_SPONSERS, GET_SPECIFIC_SPONSER, UPDATE_SPONSER } from "./actionTypes"
import { getSponserSuccess, getSponserFail, updateSponserLoading1, updateSponserSuccess, deleteSponserSuccess } from "./actions"
import service from "../../helpers/Api/FetchInterceptor"

//Include Both Helper File with needed methods
function getSponsers(params) {
  return async function () {
    let result = await service.get('/sponsers' + "?" + params);
    return result.data;
  }
}
function getSponser(id) {
  return async function () {
    let result = await service.get('/sponsers/' + id);
    return result.data;
  }
}

function putSponser(sponser) {
  return async function () {
    const formData = new FormData();
    Object.keys(sponser).forEach(key => formData.append(key, object[key]));
    let result = await service.put('/sponsers', {
      data: formData
    });
    return result.data;
  }
}

function* fetchSponser({
  payload: params
}) {
  try {
    const response = yield call(getSponsers(params))
    yield put(getSponserSuccess(response))
  } catch (error) {
    yield put(getSponserFail(error))
  }
}

function* addSponser({
  payload
}) {
  try {
    yield put(updateSponserLoading1(true))
    const response = yield call(() => payload)
    yield put(updateSponserSuccess(response.data.sponser))
  } catch (error) {
    yield put(getSponserFail(error))
  } finally {
    yield put(updateSponserLoading1(false))
  }
}

function* updateSponser({
  payload
}) {
  try {
    yield put(updateSponserLoading1(true))
    const response = yield call(() => payload)
    yield put(updateSponserSuccess(response.data.sponser))
  } catch (error) {
    yield put(getSponserFail(error))
  } finally {
    yield put(updateSponserLoading1(false))
  }
}

function* fetchSponserWithId({
  payload
}) {
  try {
    yield put(updateSponserLoading1(true))
    const response = yield call(() => payload)
    yield put(updateSponserSuccess(response.data.sponser))
  } catch (error) {
    yield put(getSponserFail(error))
  } finally {
    yield put(updateSponserLoading1(false))
  }
}

function* deleteSponser({
  payload
}) {
  try {
    yield put(updateSponserLoading1(true))
    const response = yield call(() => payload)
    console.log('called', response)
    yield put(deleteSponserSuccess(response.data.sponser))
  } catch (error) {
    yield put(getSponserFail(error))
  } finally {
    yield put(updateSponserLoading1(false))
  }
}

function* fetchTest({
  payload
}) {
  try {
    yield put(updateSponserLoading1(true))
    const response = yield call(() => payload)
  } catch (error) {
    yield put(getSponserFail(error))
  } finally {
    yield put(updateSponserLoading1(false))
  }
}

function* sponsersSaga() {
  yield takeEvery(GET_SPONSERS, fetchSponser)
  yield takeEvery(ADD_SPONSER, addSponser)
  yield takeEvery(UPDATE_SPONSER, updateSponser)
  yield takeEvery(GET_SPECIFIC_SPONSER, fetchSponserWithId)
  yield takeEvery(DELETE_SPONSER, deleteSponser)
  yield takeEvery(SPONSER_TEST, fetchTest)
}

export default sponsersSaga
