import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { history } from '../../App';
import service from '../../helpers/Api/FetchInterceptor';

const API_URL = "/categories"

// Define a thunk function for fetching data
export const fetchCategorys = createAsyncThunk(
  'categorys/fetchCategorys',
  async (params) => {
    let result = await service.get(API_URL + "?" + params);
    return result.data;
  }
);

// Define a thunk function for creating data
export const fetchSpecificCategorys = createAsyncThunk(
  'categorys/fetchSpecificCategorys',
  async (id) => {
    const result = await service.get(API_URL + "/" + id);
    return result.data;
  }
);
// Define a thunk function for creating data
export const createCategory = createAsyncThunk(
  'categorys/createCategory',
  async (newCategory) => {
    // const formData = new FormData();
    // Object.keys(newCategory).forEach(key => formData.append(key, newCategory[key]));
    let result = await service({
      method:"post",
      url: API_URL,
      data: newCategory
    });
    return result.data;
  }
);

// Define a thunk function for updating data
export const updateCategory = createAsyncThunk(
  'categorys/updateCategory',
  async (updatedCategory) => {
    const [id, values] =  updatedCategory;
    console.log("UPDATE", updatedCategory)
    // const formData = new FormData();
    // Object.keys(values).forEach(key => formData.append(key, JSON.stringify(values[key])));
    let result = await service({
      url: API_URL + "/" + id,
      method:"POST",
      data: values
    });
    return result.data;
  }
);

// Define a thunk function for deleting data
export const deleteCategory = createAsyncThunk(
  'categorys/deleteCategory',
  async (id) => {
    await service.delete(`${API_URL}/${id}`);
    return id;
  }
);

// Define a slice with initial state and reducers
const categorysSlice = createSlice({
  name: 'categorys',
  initialState: {
    single: {},
    list: [],
    pagination: {
      pageSize: 5,
      current: 0,
      total: 0,
    },
    isLoading: false,
    sort: {},
    error: null,
  },
  reducers: {
    deleteCategoryCategoryDetailsInsideArray: (state, action) => {
      console.log("START DELETE", action.payload)
      state.list.forEach((val, i) => {
        if(val.category_details?.find(v => v.id == action.payload)) {
          console.log("FIND DELETE", val, "INDEX: ", i)
          state.list[i] = {
            ...state.list[i],
            category_details: val.category_details.filter(x => x.id != action.payload)
          }
        }
      });
      state.list = [...state.list]
    },
  },
  extraReducers: (builder) => {
    // Add reducers for fetching data
    builder
      .addCase(fetchCategorys.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCategorys.fulfilled, (state, action) => {
        state.isLoading = false;
        const data = action.payload
        state.list = data?.data
        state.pagination = {
          pageSize: data?.per_page,
          current: data?.current_page,
          total: data?.total,
        }
      })
      .addCase(fetchCategorys.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Add reducers for fetching data
    builder
      .addCase(fetchSpecificCategorys.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSpecificCategorys.fulfilled, (state, action) => {
        state.isLoading = false;
        const data = action.payload
        state.single = data?.category
      })
      .addCase(fetchSpecificCategorys.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Add reducers for creating data
    builder
      .addCase(createCategory.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list.push(action.payload);
        history.push("/categories")
      })
      .addCase(createCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Add reducers for updating data
    builder
      .addCase(updateCategory.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.list.findIndex(category => category.id === action.payload.id);
        if (index !== -1) {
          state.list[index] = action.payload;
        }
        history.push("/categories")
      })
      .addCase(updateCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(deleteCategory.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list = state.list.filter(category => category.id !== action.payload);
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  }
})

// Export the action creators and the reducer
export const { deleteCategoryCategoryDetailsInsideArray } = categorysSlice.actions;
export default categorysSlice.reducer;
