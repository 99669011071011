import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import Dropzone from "react-dropzone"
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from "yup";
import Switch from "react-switch"
import { createPincodeCharge, fetchSpecificPincodeCharges, updatePincodeCharge } from '../../store/actions'
import { message, Spin } from 'antd'

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      No
    </div>
  )
}

const OnSymbol = props => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      Yes
    </div>
  )
}

export class PincodeChargeOperations extends Component {
  static propTypes = {
    type: PropTypes.any
  }


  constructor(props) {
    super(props)
    this.state = {
      selectedFiles: [],
      isSubmiting: false
    }
  }


  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    )

    this.setState({ selectedFiles: files })
  }

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  componentDidMount() {
    const { match } = this.props;
    if (this.props.type == "EDIT") {
      console.log('Getting ID', match.params.id)
      this.props.fetchSpecificPincodeCharges(match.params.id)
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="PincodeCharges" breadcrumbItem={`${this.props.type == "ADD" ? 'Add' : 'Update'} PincodeCharge`} />

            <Row>
              <Col xs="12">
                <Spin spinning={this.props.isLoading}>
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">Basic Information</CardTitle>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          ...(this.props.type == "EDIT" ? this.props.pincodeCharge : {})
                        }}
                        validationSchema={
                          Yup.object().shape({
                            pincode: Yup.string().required('Postal Code is required'),
                            b_to_b_charge: Yup.string().required('Delivery Charges is required'),
                            b_to_c_charge: Yup.string().required('Delivery Charges is required'),
                          })}
                        onSubmit={values => {
                          const that = this;
                          this.setState({ isSubmiting: true })
                          values.is_active = values.is_active ? 1 : 0;
                          let apiSubmit = null;
                          if (this.props.type == "EDIT") {
                            values._method = 'patch'
                            this.props.updatePincodeCharge([this.props.match.params.id, values])
                          } else {
                            this.props.createPincodeCharge(values)
                          }
                        }}
                      >
                        {({ errors, status, touched, setFieldValue, validateForm, values, }) => (
                          <Form className="form-horizontal">
                            <Row>

                              <Col sm="6">
                                <div className="mb-3">
                                  <Label for="name" className="form-label">
                                    Pin Code *
                                  </Label>
                                  <Field
                                    name="pincode"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.pincode
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage name="pincode" render={msg => <div className="error">{msg}</div>} />
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label for="text" className="form-label">
                                    Delivery Charge BTOB*
                                  </Label>
                                  <Field
                                    name="b_to_b_charge"
                                    type="number"
                                    className={
                                      "form-control" +
                                      (errors.b_to_b_charge
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage name="b_to_b_charge" render={msg => <div className="error">{msg}</div>} />
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label for="text" className="form-label">
                                    Delivery Charge BTOC *
                                  </Label>
                                  <Field
                                    name="b_to_c_charge"
                                    type="number"
                                    className={
                                      "form-control" +
                                      (errors.b_to_c_charge
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage name="b_to_c_charge" render={msg => <div className="error">{msg}</div>} />
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label className='pr-3'>PincodeCharge Status</Label> <br />
                                  <Switch
                                    uncheckedIcon={<Offsymbol />}
                                    className="me-1 mb-sm-8 mb-2"
                                    checkedIcon={<OnSymbol />}
                                    onColor="#626ed4"
                                    onChange={() =>
                                      setFieldValue('is_active', values.is_active ? !true : !false)
                                    }
                                    checked={values.is_active ? true : false}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <div className="d-flex flex-wrap gap-2 mt-4">
                              <Button
                                type="submit"
                                color="primary"
                                disabled={this.props.isLoading}
                              >

                                {this.props.isLoading ? ' Saving....' : 'Save Changes'}
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Formik>

                    </CardBody>
                  </Card>
                </Spin>

              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment >
    )
  }
}

const mapStateToProps = (state) => ({
  pincodeCharge: state.PincodeCharge.single,
  isLoading: state.PincodeCharge.isLoading
})

const mapDispatchToProps = dispatch => ({
  createPincodeCharge: payload => dispatch(createPincodeCharge(payload)),
  updatePincodeCharge: (payload) => dispatch(updatePincodeCharge(payload)),
  fetchSpecificPincodeCharges: payload => dispatch(fetchSpecificPincodeCharges(payload))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PincodeChargeOperations))

