import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import Dropzone from "react-dropzone"
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from "yup";
import Switch from "react-switch"
import { createBanner, fetchSpecificBanner, updateBanner } from '../../store/actions'
import { message, Spin } from 'antd'
import service from 'helpers/Api/FetchInterceptor';
import { history } from 'App';

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      No
    </div>
  )
}

const OnSymbol = props => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      Yes
    </div>
  )
}

export class TestimonialOperations extends Component {
  static propTypes = {
    type: PropTypes.any
  }


  constructor(props) {
    super(props)
    this.state = {
      selectedFiles: [],
      isSubmiting: false,
      isLoading: false,
      fetchData: {}
    }
  }

  componentDidMount() {
    const { match } = this.props;
    if (this.props.type == "EDIT") {
      console.log('Getting ID', match.params.id)
      this.fetchSpecificAPI(match.params.id)
    }
  }

  fetchSpecificAPI(id) {
    this.setState({
      isLoading: true
    })
    service.get('/banners' + "/" + id)
      .then(res => {
        const result = res.data;
        this.setState({
          isLoading: false,
          fetchData: result.banner
        })
      })
      .catch(err => {
        this.setState({
          isLoading: false,
        })
      })
  }

  createAPI(values) {

    const formData = new FormData();
    Object.keys(values).forEach(key => formData.append(key, values[key]));

    this.setState({
      isLoading: true
    })
    service({
      method: "POST",
      url: '/banners',
      data: formData
    })
      .then(res => {
        const result = res.data;
        history.push("/banners")
        this.setState({
          isLoading: false,
          fetchData: result.banner
        })
      })
      .catch(err => {
        this.setState({
          isLoading: false,
        })
      })
  }

  updateAPI(list = []) {
    const [id, values] = list;
    console.log("UPDATE", list)
    const formData = new FormData();
    Object.keys(values).forEach(key => formData.append(key, values[key]));

    this.setState({
      isLoading: true
    })
    service({
      method: "POST",
      url: '/banners' + "/" + id,
      data: formData
    })
      .then(res => {
        const result = res.data;
        history.push("/banners")
        this.setState({
          isLoading: false,
          fetchData: result.banner
        })
      })
      .catch(err => {
        this.setState({
          isLoading: false,
        })
      })
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Banner" breadcrumbItem={`${this.props.type == "ADD" ? 'Add' : 'Update'} Banner`} />
            {console.log(this.state.fetchData, "FETCH DATA")}
            <Row>
              <Col xs="12">
                <Spin spinning={this.state.isLoading}>
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">Basic Information</CardTitle>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          banner_type: "b_to_b",
                          ...(this.props.type == "EDIT" ? this.state.fetchData : {})
                        }}
                        validationSchema={
                          Yup.object().shape({
                            name: Yup.string().required('Name is required'),
                            priority: Yup.string().required('Priority is required'),
                            url: Yup.string().required('URL is required'),
                            // description: Yup.string().required('Description is required'),
                            banner: (() => {
                              return (this.props.type == "EDIT" ? Yup.mixed() : Yup.mixed().required('Icon is required'))
                            })()
                          })}
                        onSubmit={values => {
                          const that = this;
                          this.setState({ isSubmiting: true })
                          values.is_active = values.is_active ? 1 : 0;
                          let apiSubmit = null;
                          if (this.props.type == "EDIT") {
                            if (!(values.banner instanceof File)) {
                              delete values['banner']
                            }
                            values._method = 'patch'
                            this.updateAPI([this.props.match.params.id, values])
                          } else {
                            this.createAPI(values)
                          }
                        }}
                      >
                        {({ errors, status, touched, setFieldValue, validateForm, values, }) => (
                          <Form className="form-horizontal">
                            <Row>

                              <Col sm="6">
                                <div className="mb-3">
                                  <Label for="name" className="form-label">
                                    Banner Name *
                                  </Label>
                                  <Field
                                    name="name"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.name
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage name="name" render={msg => <div className="error">{msg}</div>} />
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label for="name" className="form-label">
                                    Banner URL *
                                  </Label>
                                  <Field
                                    name="url"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.url
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage name="url" render={msg => <div className="error">{msg}</div>} />
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label for="text" className="form-label">
                                    Banner priority  *
                                  </Label>
                                  <Field
                                    name="priority"
                                    type="number"
                                    className={
                                      "form-control" +
                                      (errors.priority
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage name="priority" render={msg => <div className="error">{msg}</div>} />
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label for="text" className="form-label">
                                    Banner Image
                                  </Label>
                                  <input type="file" className={
                                    "form-control" +
                                    (errors.banner
                                      ? " is-invalid"
                                      : "")
                                  } onChange={(event) => {
                                    setFieldValue("banner", event.currentTarget.files[0]);
                                  }} />
                                  <ErrorMessage name="banner" render={msg => <div className="error">{msg}</div>} />
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label className='pr-3'>Banner Status</Label> <br />
                                  <Switch
                                    uncheckedIcon={<Offsymbol />}
                                    className="me-1 mb-sm-8 mb-2"
                                    checkedIcon={<OnSymbol />}
                                    onColor="#626ed4"
                                    onChange={() =>
                                      setFieldValue('is_active', values.is_active ? !true : !false)
                                    }
                                    checked={values.is_active ? true : false}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <div className="d-flex flex-wrap gap-2 mt-4">
                              <Button
                                type="submit"
                                color="primary"
                                disabled={this.props.isLoading}
                              >

                                {this.props.isLoading ? ' Saving....' : 'Save Changes'}
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Formik>

                    </CardBody>
                  </Card>
                </Spin>

              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment >
    )
  }
}

const mapStateToProps = (state) => ({
  // banner: state.Banner.single,
  // isLoading: state.Banner.isLoading
})

const mapDispatchToProps = dispatch => ({
  // createBanner: payload => dispatch(createBanner(payload)),
  // updateBanner: (payload) => dispatch(updateBanner(payload)),
  // fetchSpecificBanner: payload => dispatch(fetchSpecificBanner(payload))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TestimonialOperations))

