import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { history } from '../../App';
import service from '../../helpers/Api/FetchInterceptor';

const API_URL = "/brands"

// Define a thunk function for fetching data
export const fetchBrands = createAsyncThunk(
  'brands/fetchBrands',
  async (params) => {
    let result = await service.get(API_URL + "?" + params);
    return result.data;
  }
);

// Define a thunk function for creating data
export const fetchSpecificBrands = createAsyncThunk(
  'brands/fetchSpecificBrands',
  async (id) => {
    const result = await service.get(API_URL + "/" + id);
    return result.data;
  }
);
// Define a thunk function for creating data
export const createBrand = createAsyncThunk(
  'brands/createBrand',
  async (newBrand) => {
    const formData = new FormData();
    Object.keys(newBrand).forEach(key => formData.append(key, newBrand[key]));
    let result = await service({
      method:"post",
      url: API_URL,
      data: formData
    });
    return result.data;
  }
);

// Define a thunk function for updating data
export const updateBrand = createAsyncThunk(
  'brands/updateBrand',
  async (updatedBrand) => {
    const [id, values] =  updatedBrand;
    console.log("UPDATE", updatedBrand)
    const formData = new FormData();
    Object.keys(values).forEach(key => formData.append(key, values[key]));
    let result = await service({
      url: API_URL + "/" + id,
      method:"POST",
      data: formData
    });
    return result.data;
  }
);

// Define a thunk function for deleting data
export const deleteBrand = createAsyncThunk(
  'brands/deleteBrand',
  async (id) => {
    await service.delete(`${API_URL}/${id}`);
    return id;
  }
);

// Define a slice with initial state and reducers
const brandsSlice = createSlice({
  name: 'brands',
  initialState: {
    single: {},
    list: [],
    pagination: {
      pageSize: 5,
      current: 0,
      total: 0,
    },
    isLoading: false,
    sort: {},
    error: null,
  },
  reducers: {
    deleteBrandBrandDetailsInsideArray: (state, action) => {
      console.log("START DELETE", action.payload)
      state.list.forEach((val, i) => {
        if(val.brand_details?.find(v => v.id == action.payload)) {
          console.log("FIND DELETE", val, "INDEX: ", i)
          state.list[i] = {
            ...state.list[i],
            brand_details: val.brand_details.filter(x => x.id != action.payload)
          }
        }
      });
      state.list = [...state.list]
    },
  },
  extraReducers: (builder) => {
    // Add reducers for fetching data
    builder
      .addCase(fetchBrands.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchBrands.fulfilled, (state, action) => {
        state.isLoading = false;
        const data = action.payload
        state.list = data?.data
        state.pagination = {
          pageSize: data?.per_page,
          current: data?.current_page,
          total: data?.total,
        }
      })
      .addCase(fetchBrands.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Add reducers for fetching data
    builder
      .addCase(fetchSpecificBrands.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSpecificBrands.fulfilled, (state, action) => {
        state.isLoading = false;
        const data = action.payload
        state.single = data?.brand
      })
      .addCase(fetchSpecificBrands.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Add reducers for creating data
    builder
      .addCase(createBrand.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createBrand.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list.push(action.payload);
        history.push("/brands")
      })
      .addCase(createBrand.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Add reducers for updating data
    builder
      .addCase(updateBrand.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateBrand.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.list.findIndex(brand => brand.id === action.payload.id);
        if (index !== -1) {
          state.list[index] = action.payload;
        }
        history.push("/brands")
      })
      .addCase(updateBrand.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(deleteBrand.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteBrand.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list = state.list.filter(brand => brand.id !== action.payload);
      })
      .addCase(deleteBrand.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  }
})

// Export the action creators and the reducer
export const { deleteBrandBrandDetailsInsideArray } = brandsSlice.actions;
export default brandsSlice.reducer;
