import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { history } from '../../App';
import service from '../../helpers/Api/FetchInterceptor';

const API_URL = "/coupons"

// Define a thunk function for fetching data
export const fetchCoupons = createAsyncThunk(
  'coupons/fetchCoupons',
  async (params) => {
    let result = await service.get(API_URL + "?" + params);
    return result.data;
  }
);

// Define a thunk function for creating data
export const fetchSpecificCoupons = createAsyncThunk(
  'coupons/fetchSpecificCoupons',
  async (id) => {
    const result = await service.get(API_URL + "/" + id);
    return result.data;
  }
);
// Define a thunk function for creating data
export const createCoupon = createAsyncThunk(
  'coupons/createCoupon',
  async (newCoupon) => {
    const formData = new FormData();
    Object.keys(newCoupon).forEach(key => formData.append(key, newCoupon[key]));
    let result = await service({
      method:"post",
      url: API_URL,
      data: formData
    });
    return result.data;
  }
);

// Define a thunk function for updating data
export const updateCoupon = createAsyncThunk(
  'coupons/updateCoupon',
  async (updatedCoupon) => {
    const [id, values] =  updatedCoupon;
    console.log("UPDATE", updatedCoupon)
    const formData = new FormData();
    Object.keys(values).forEach(key => formData.append(key, values[key]));
    let result = await service({
      url: API_URL + "/" + id,
      method:"POST",
      data: formData
    });
    return result.data;
  }
);

// Define a thunk function for deleting data
export const deleteCoupon = createAsyncThunk(
  'coupons/deleteCoupon',
  async (id) => {
    await service.delete(`${API_URL}/${id}`);
    return id;
  }
);

// Define a slice with initial state and reducers
const couponsSlice = createSlice({
  name: 'coupons',
  initialState: {
    single: {},
    list: [],
    pagination: {
      pageSize: 5,
      current: 0,
      total: 0,
    },
    isLoading: false,
    sort: {},
    error: null,
  },
  reducers: {
    deleteCouponCouponDetailsInsideArray: (state, action) => {
      console.log("START DELETE", action.payload)
      state.list.forEach((val, i) => {
        if(val.coupon_details?.find(v => v.id == action.payload)) {
          console.log("FIND DELETE", val, "INDEX: ", i)
          state.list[i] = {
            ...state.list[i],
            coupon_details: val.coupon_details.filter(x => x.id != action.payload)
          }
        }
      });
      state.list = [...state.list]
    },
  },
  extraReducers: (builder) => {
    // Add reducers for fetching data
    builder
      .addCase(fetchCoupons.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCoupons.fulfilled, (state, action) => {
        state.isLoading = false;
        const data = action.payload
        state.list = data?.data
        state.pagination = {
          pageSize: data?.per_page,
          current: data?.current_page,
          total: data?.total,
        }
      })
      .addCase(fetchCoupons.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Add reducers for fetching data
    builder
      .addCase(fetchSpecificCoupons.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSpecificCoupons.fulfilled, (state, action) => {
        state.isLoading = false;
        const data = action.payload
        state.single = data?.coupon
      })
      .addCase(fetchSpecificCoupons.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Add reducers for creating data
    builder
      .addCase(createCoupon.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createCoupon.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list.push(action.payload);
        history.push("/coupon")
      })
      .addCase(createCoupon.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Add reducers for updating data
    builder
      .addCase(updateCoupon.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateCoupon.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.list.findIndex(coupon => coupon.id === action.payload.id);
        if (index !== -1) {
          state.list[index] = action.payload;
        }
        history.push("/coupon")
      })
      .addCase(updateCoupon.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(deleteCoupon.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteCoupon.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list = state.list.filter(coupon => coupon.id !== action.payload);
      })
      .addCase(deleteCoupon.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  }
})

// Export the action creators and the reducer
export const { deleteCouponCouponDetailsInsideArray } = couponsSlice.actions;
export default couponsSlice.reducer;
