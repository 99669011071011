import { useState, useCallback, useEffect } from "react";

import qs from "qs";

var isObj = function (a) {
  if (!!a && a.constructor === Object) {
    return true;
  }
  return false;
};
var _st = function (z, g) {
  return "" + (g != "" ? "[" : "") + z + (g != "" ? "]" : "");
};
var fromObject = function (params, skipobjects, prefix) {
  if (skipobjects === void 0) {
    skipobjects = false;
  }
  if (prefix === void 0) {
    prefix = "";
  }
  var result = "";
  if (typeof params != "object") {
    return prefix + "=" + encodeURIComponent(params) + "&";
  }
  for (var param in params) {
    var c = "" + prefix + _st(param, prefix);
    if (isObj(params[param]) && !skipobjects) {
      result += fromObject(params[param], false, "" + c);
    } else if (Array.isArray(params[param]) && !skipobjects) {
      params[param].forEach(function (item, ind) {
        result += fromObject(item, false, c + "[" + ind + "]");
      });
    } else {
      result += c + "=" + encodeURIComponent(params[param]) + "&";
    }
  }
  return result;
};

const setQueryStringWithoutPageReload = (qsValue) => {
  const newurl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    qsValue;

  window.history.pushState({ path: newurl }, "", newurl);
};

const setQueryStringValue = (
  value,
  queryString = window.location.search
) => {
  const values = qs.parse(queryString);
  console.log(values, queryString, value, "===========================================>Query");
  const newQsValue = (qs.stringify({ ...value }));
  setQueryStringWithoutPageReload(`?${newQsValue}`);
};

export const getQueryStringValue = (
  key,
  queryString = window.location.search
) => {
  const values = qs.parse(queryString);
  return key ? values[key] : values;
};
const queryStringToObject = (url) =>
  [...new URLSearchParams(url.split("?")[1])].reduce(
    (a, [k, v]) => ((a[k] = v), a),
    {}
  );

const getDefaultValue = (defaultValue) => {
  const queryString = window.location.search.replace('?', '')
  const queryValues = qs.parse(queryString);
  return {...defaultValue, ...queryValues};
}

function useQueryString(initialValue) {
  const [value, setValue] = useState(getDefaultValue(initialValue));
  // const onSetValue = useCallback(
  //   (newValue) => {
  //     setValue(newValue);
  //     setQueryStringValue(key, newValue);
  //   },
  //   [key]
  // );
  useEffect(() => {
    setQueryStringValue(initialValue);
  }, []);

  const onSetValue = (newValue) => {
    setValue(newValue);
    setQueryStringValue(newValue);
  };
  return [value, onSetValue];
}

export default useQueryString;
