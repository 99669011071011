import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import classNames from "classnames";

// Import FecthAPI
import service from "../../helpers/Api/FetchInterceptor";
import Lightbox from "react-image-lightbox";
import { deleteOrder, fetchOrders } from "../../store/actions";
import PaginateTableSaga from "../../components/UI/Table/TableSaga";
import { Popconfirm, Tag, Table, Dropdown } from "antd";
import { MoreOutlined, EditOutlined, DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons"
import moment from "moment";
import { OrderStaus } from "./Component/OrderStatus";

function Test(props) {
  const [visible, setVisible] = useState(false)
  return (
    <React.Fragment>
      <img src={props.row.logo} style={{ width: '200px' }} onClick={() => setVisible(true)} />
      {visible && (
        <Lightbox
          mainSrc={props.row.logo}
          enableZoom={false}
          imageCaption={props.row.company_name}
          onCloseRequest={() => { setVisible(false) }}
        />
      )}
    </React.Fragment>
  )
}

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customQuery: {},
      status_update_modal: false
    };

    this.tog_status_update = this.tog_status_update.bind(this)

    this.tableColumns = [
      {
        title: "ID",
        dataIndex: "id",
        sorter: true,
      },

      {
        title: "User",
        dataIndex: "user_id",
        sorter: true,
        render: (text, row) => {
          return (
            <>
              <h6>{row.customer.name}</h6>
              <span>
                {row.customer.email}
              </span>
            </>
          )
        }
      },
      {
        title: "Address",
        dataIndex: "address",
        sorter: true,
      },
      {
        title: "Order Type",
        dataIndex: "order_type",
        sorter: true,
      },
      {
        title: "Payment Mode",
        dataIndex: "payment_mode",
        sorter: true,
      },
      {
        title: "Order Total",
        dataIndex: "order_total",
        sorter: true,
      },
      {
        title: "Partial",
        dataIndex: "partial",
        sorter: true,
      },
      {
        title: "Canceled",
        dataIndex: "is_canceled",
        render: (text, row) => {
          return (
            <>
              {row.is_canceled == 1 ? (
                <Tag color={"red"}>Yes</Tag>
              ) : (
                <Tag color={"green"}>No</Tag>
              )}
            </>
          );
        },
        sorter: true,
      },
      // {
      //   title: "Total Order",
      //   dataIndex: "",
      //   render: (row) => row?.order_details?.length || 0
      // },
      {
        title: "Order Time",
        dataIndex: "is_active",
        render: (text, row) => {
          return (
            <>
              <Tag>{moment(row.created_at).format("YYYY-MM-DD HH:mm A")}</Tag>
            </>
          );
        },
        sorter: true,
      },
      {
        title: "Action",
        dataIndex: "",
        sorter: false,
        render: (_, row) => (
          <>
            <div className="d-flex gap-3">
              {/* <Link className="text-success" to={"#"}>
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                onClick={() => this.tog_status_update()}
                ></i>
              </Link> */}
              <Link className="text-dark" to={`/orders/${row.id}/details`}>
                <i
                  className="mdi mdi-eye font-size-18"
                  id="edittooltip"
                ></i>
              </Link>
              {/* <Popconfirm
                title="Are you sure?"
                className="text-danger cursor-pointer"
                description="You want to Delete"
                onConfirm={() => { }}
                onOpenChange={() => console.log('open change')}
              >
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                // onClick={() => this.onClickDelete(user)}
                ></i>
              </Popconfirm> */}
            </div>
          </>
        )
      },
    ];

  }

  tog_status_update() {
    this.setState(prevState => ({
      status_update_modal: !prevState.status_update_modal,
    }))
  }



  async fetchApi(params) {
    let result = await service.get('/orders' + "?" + params);
    return result.data;
  }

  componentDidMount() {
    // this.props.fetchOrder("");
  }

  componentDidUpdate(prevProps) {
  }

  render() {
    //meta title
    document.title = "Orders";

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("Dashboards")}
              breadcrumbItem={this.props.t("Orders")}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row className="mb-4">
                      <Col sm="4">
                      </Col>
                      <Col sm="8">
                        <div className="text-sm-end">
                          <Link to={'/orders/create'}>
                            <Button
                              color="success"
                              className="font-16 btn-block btn btn-success"
                            >
                              <i className="mdi mdi-plus-circle-outline me-1" />
                              Create New Order
                            </Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                    <Modal
                      isOpen={this.state.status_update_modal}
                      toggle={this.tog_status_update}
                      centered={true}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0">Order Status</h5>
                        <button
                          type="button"
                          onClick={() =>
                            this.tog_status_update()
                          }
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <OrderStaus></OrderStaus>
                      </div>
                    </Modal>
                    <PaginateTableSaga
                      fetch={this.props.fetchOrder}
                      isLoading={this.props.orderState.isLoading}
                      columns={this.tableColumns}
                      list={this.props.orders}
                      pagination={this.props.orderState.pagination}
                      customeQuery={this.customQuery}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* Render Breadcrumb */}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Orders.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = ({ Order }) => {
  return ({
    orderState: Order,
    orders: Order.list,
  })
};

const mapDispatchToProps = dispatch => ({
  fetchOrder: (params) => dispatch(fetchOrders(params)),
  deleteOrder: (prams) => dispatch(deleteOrder(prams)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Orders));
