import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';

const AddProduct = ({ show, handleClose, handleFormSubmit }) => {

  const products = useSelector(state => state.Product.list)

  const initialValues = {
    product: '',
    quantity: 0
  };

  const validationSchema = Yup.object().shape({
    product: Yup.string().required('Product is required'),
    quantity: Yup.number().min(1, 'Quantity must be at least 1').required('Quantity is required')
  });

  const handleSubmit = (values) => {
    handleFormSubmit(values);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>Select Product</Form.Label>
                <Form.Control as="select" name="product" value={values.product} onChange={handleChange} onBlur={handleBlur} isInvalid={touched.product && errors.product}>
                  <option value="">--Select--</option>
                  {products.map(prd => (
                    <option value={prd.id} key={prd.id}>
                      {prd.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.product}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Quantity</Form.Label>
                <Form.Control type="number" name="quantity" value={values.quantity} onChange={handleChange} onBlur={handleBlur} isInvalid={touched.quantity && errors.quantity} />
                <Form.Control.Feedback type="invalid">{errors.quantity}</Form.Control.Feedback>
              </Form.Group>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                <Button type="submit" variant="primary" disabled={isSubmitting}>Submit</Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddProduct;
