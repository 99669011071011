import { ErrorMessage, Field, Formik } from 'formik'
import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Button, Col, Form, Label, Row } from 'reactstrap'
import { updateOrderStatus } from 'store/actions';
import * as Yup from "yup";

export const OrderStaus = (props) => {

  const order = useSelector((state) => state.Order.single);
  const dispatch = useDispatch()


  return (
    <Formik
      // enableReinitialize={true}
      initialValues={{
        order_status: props.orderStatus || ""
      }}
      validationSchema={
        Yup.object().shape({
          order_status: Yup.string().required('Order status is required'),
        })}
      onSubmit={async values => {
        console.log(props.props, "TETST", props.state, order);
        await dispatch(updateOrderStatus({
          id: props.orderId,
          order_status: values.order_status,
        }))

        props.toggle()
      }}
    >

      {({ errors, status, touched, setFieldValue, validateForm, values, submitForm }) => (
        <Form onSubmit={(e) => e.preventDefault()} className="form-horizontal">
          <Row>
            <Col sm="12">
              <div className="mb-3">
                <Label for="name" className="form-label">
                  Order Status *
                </Label>
                <Field component="select" name="order_status" className={
                  "form-control" +
                  (errors.order_status
                    ? " is-invalid"
                    : "")
                }>
                  <option value={""}>
                    ----Select Order Status----
                  </option>
                  <option value={"Order recieved"}>
                    Order recieved
                  </option>
                  <option value={"Purchase order sent"}>
                    Purchase order sent
                  </option>
                  <option value={"Out for delivery"}>
                    Out for delivery
                  </option>
                  <option value={"Delivered"}>
                    Delivered
                  </option>
                  <option value={"Out of stock"}>
                    Out of stock
                  </option>
                  <option value={"Return Initiated"}>
                    Return Initiated
                  </option>
                  <option value={"Return"}>
                    Return
                  </option>
                </Field>
                <ErrorMessage name="order_status" render={msg => <div className="error">{msg}</div>} />
              </div>
            </Col>
          </Row>

          <div className="d-flex flex-wrap gap-2 mt-4">
            <Button
              type="submit"
              color="primary"
              onClick={submitForm}
              disabled={props.isLoading}
            >

              {props.isLoading ? ' Saving....' : 'Save Changes'}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const mapStateToProps = (state) => {
  console.log("STATE", state)
  return ({
    order: state.Order.single,
    isLoading: state.Order.isLoading,
  })
}

const mapDispatchToProps = dispatch => ({
  updateOrderStatus: payload => dispatch(updateOrderStatus(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderStaus)