import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { ADD_USER, USER_TEST, DELETE_USER, GET_USERS, GET_SPECIFIC_USER, UPDATE_USER } from "./actionTypes"
import { getUserSuccess, getUserFail, updateUserLoading1, updateUserSuccess, deleteUserSuccess } from "./actions"
import service from "../../helpers/Api/FetchInterceptor"

//Include Both Helper File with needed methods
function getUsers(params) {
  return async function () {
    let result = await service.get('/user' + "?" + params);
    return result.data;
  }
}
function getUser(id) {
  return async function () {
    let result = await service.get('/user/' + id);
    return result.data;
  }
}

function putUser(user) {
  return async function () {
    const formData = new FormData();
    Object.keys(user).forEach(key => formData.append(key, object[key]));
    let result = await service.put('/user', {
      data: formData
    });
    return result.data;
  }
}

function* fetchUser({
  payload: params
}) {
  try {
    const response = yield call(getUsers(params))
    yield put(getUserSuccess(response))
  } catch (error) {
    yield put(getUserFail(error))
  }
}

function* addUser({
  payload
}) {
  try {
    yield put(updateUserLoading1(true))
    const response = yield call(() => payload)
    yield put(updateUserSuccess(response.data.user))
  } catch (error) {
    yield put(getUserFail(error))
  } finally {
    yield put(updateUserLoading1(false))
  }
}

function* updateUser({
  payload
}) {
  try {
    yield put(updateUserLoading1(true))
    const response = yield call(() => payload)
    yield put(updateUserSuccess(response.data.user))
  } catch (error) {
    yield put(getUserFail(error))
  } finally {
    yield put(updateUserLoading1(false))
  }
}

function* fetchUserWithId({
  payload
}) {
  try {
    yield put(updateUserLoading1(true))
    const response = yield call(() => payload)
    yield put(updateUserSuccess(response.data.user))
  } catch (error) {
    yield put(getUserFail(error))
  } finally {
    yield put(updateUserLoading1(false))
  }
}

function* deleteUser({
  payload
}) {
  try {
    yield put(updateUserLoading1(true))
    const response = yield call(() => payload)
    console.log('called', response)
    yield put(deleteUserSuccess(response.data.user))
  } catch (error) {
    yield put(getUserFail(error))
  } finally {
    yield put(updateUserLoading1(false))
  }
}

function* fetchTest({
  payload
}) {
  try {
    yield put(updateUserLoading1(true))
    const response = yield call(() => payload)
  } catch (error) {
    yield put(getUserFail(error))
  } finally {
    yield put(updateUserLoading1(false))
  }
}

function* usersSaga() {
  yield takeEvery(GET_USERS, fetchUser)
  yield takeEvery(ADD_USER, addUser)
  yield takeEvery(UPDATE_USER, updateUser)
  yield takeEvery(GET_SPECIFIC_USER, fetchUserWithId)
  yield takeEvery(DELETE_USER, deleteUser)
  yield takeEvery(USER_TEST, fetchTest)
}

export default usersSaga
