import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import classNames from "classnames";

// Import FecthAPI
import service from "../../helpers/Api/FetchInterceptor";
import Lightbox from "react-image-lightbox";
import { fetchQuerys, queryMarkAsReaded } from "../../store/actions";
import PaginateTableSaga from "../../components/UI/Table/TableSaga";
import { Dropdown, Popconfirm, Tag } from "antd";
import { EyeOutlined, MoreOutlined} from "@ant-design/icons"
import moment from "moment";

function Test(props) {
  const [visible, setVisible] = useState(false)
  return (
    <React.Fragment>
      <img src={props.row.logo} style={{ width: '200px' }} onClick={() => setVisible(true)} />
      {visible && (
        <Lightbox
          mainSrc={props.row.logo}
          enableZoom={false}
          imageCaption={props.row.company_name}
          onCloseRequest={() => { setVisible(false) }}
        />
      )}
    </React.Fragment>
  )
}

class Querys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customQuery: {}
    };

    this.tableColumns = [
      {
        title: "ID",
        dataIndex: "id",
        sorter: true,
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: true,
      },
      {
        title: "Email",
        dataIndex: "email",
        sorter: true,
      },
      {
        title: "Phone",
        dataIndex: "phone",
        sorter: true,
      },
      {
        title: "Content",
        dataIndex: "content",
        sorter: true,
      },
      {
        title: "Readed",
        dataIndex: "is_readed",
        render: (text, row) => {
          return (
            <>
              {row.is_readed == 1 ? (
                <Tag color={"green"}>Yes</Tag>
              ) : (
                <Tag color={"red"}>No</Tag>
              )}
            </>
          );
        },
        sorter: true,
      },
      {
        title: "Created Time",
        dataIndex: "created_at",
        render: (val) => moment(val).format("YYYY-MM-DD HH:mm A"),
        sorter: true,
      },
      // {
      //   title: "Status",
      //   dataIndex: "is_active",
      //   render: (text, row) => {
      //     return (
      //       <>
      //         {row.is_active == 1 ? (
      //           <Tag color={"green"}>Active</Tag>
      //         ) : (
      //           <Tag color={"red"}>In Active</Tag>
      //         )}
      //       </>
      //     );
      //   },
      //   sorter: true,
      // },
      {
        title: "Action",
        dataIndex: "",
        sorter: false,
        render: (_, row) => (
          <>

            <Dropdown
              menu={{
                items: [
                  // {
                  //   key: '1',
                  //   label: (
                  //     <Link className="center-flex-y" to={`/event-details/${row.event_id}/${row.id}/edit`}>
                  //       <EditOutlined className="mr-2" /> Edit
                  //     </Link>
                  //   ),
                  // },
                  {
                    key: '2',
                    label: (
                      <Popconfirm
                        title="Are you sure?"
                        className="center-flex-y"
                        description="You want to Mark as Readed"
                        onConfirm={() => this.props.queryMarkAsReaded(row.id)}
                        onOpenChange={() => console.log('open change')}
                      >
                        <EyeOutlined className="mr-2" /> Mark as readed
                      </Popconfirm>
                    ),
                  }
                ],
              }}
              trigger={['click']}
              placement="bottomLeft"
            >
              <MoreOutlined />
            </Dropdown>
          </>
        )
      },
    ];
  }

  async fetchApi(params) {
    let result = await service.get('/querys' + "?" + params);
    return result.data;
  }

  componentDidMount() {
    // this.props.fetchQuery("");
  }

  componentDidUpdate(prevProps) {
  }

  render() {
    //meta title
    document.title = "Queries";

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("Dashboards")}
              breadcrumbItem={this.props.t("Queries")}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginateTableSaga fetch={this.props.fetchQuery} isLoading={this.props.queryState.isLoading} columns={this.tableColumns} list={this.props.querys} pagination={this.props.queryState.pagination} customeQuery={this.customQuery} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* Render Breadcrumb */}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Querys.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = ({ Query }) => {
  return ({
    queryState: Query,
    querys: Query.list,
  })
};

const mapDispatchToProps = dispatch => ({
  fetchQuery: (params) => dispatch(fetchQuerys(params)),
  queryMarkAsReaded: (prams) => dispatch(queryMarkAsReaded(prams))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Querys));
