import service from "../../helpers/Api/FetchInterceptor"
import { ADD_SPONSER, SPONSER_LOADING_1, SPONSER_LOADING_2, SPONSER_TEST, DELETE_SPONSER, DELETE_SPONSER_SUCCESS, GET_SPONSERS, GET_SPONSERS_FAIL, GET_SPONSERS_SUCCESS, GET_SPECIFIC_SPONSER, UPDATE_SPONSER, UPDATE_SPONSER_SUCCESS } from "./actionTypes"

export const getSponsers = (payload) => ({
  type: GET_SPONSERS,
  payload: payload
})

export const getSponserSuccess = sponsers => ({
  type: GET_SPONSERS_SUCCESS,
  payload: sponsers,
})

export const getSponserFail = error => ({
  type: GET_SPONSERS_FAIL,
  payload: error,
})

export const addSponser = (payload) => {
  const formData = new FormData();
  Object.keys(payload).forEach(key => formData.append(key, payload[key]));
  return {
    type: ADD_SPONSER,
    payload: service({
      method: "POST",
      url: "/sponsers",
      data: formData
    })
  }
}
export const updateSponser = (id, payload) => {
  const formData = new FormData();
  Object.keys(payload).forEach(key => formData.append(key, payload[key]));
  return {
    type: UPDATE_SPONSER,
    payload: service({
      method: "POST",
      url: "/sponsers/" + id,
      data: formData
    })
  }
}
export const updateSponserSuccess = (payload) => ({
  type: UPDATE_SPONSER_SUCCESS,
  payload
})

export const deleteSponser = (id) => ({
  type: DELETE_SPONSER,
  payload: service({
    method: "POST",
    url: "/sponsers/" + id,
    data: {
      '_method': 'delete'
    }
  })
})

export const deleteSponserSuccess = (payload) => ({
  type: DELETE_SPONSER_SUCCESS,
  payload
})

export const getSpecificSponser = (payload) => ({
  type: GET_SPECIFIC_SPONSER,
  payload: service.get("/sponsers/" + payload)
})


export const updateSponserLoading1 = (payload) => ({
  type: SPONSER_LOADING_1,
  payload
})
export const updateSponserLoading2 = (payload) => ({
  type: SPONSER_LOADING_2,
  payload
})