import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import axios from 'axios';
import Select from 'react-select';
import service from 'helpers/Api/FetchInterceptor';

const SearchUser = ({
  values,
  setFieldValue,
  label = "",
  formikKeyName = "selectedProduct"
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchOptions, setSearchOptions] = useState([])

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      try {
        const queryObject = {
          query: searchTerm,
          page: 1,
          perPage: 10
        }

        const productsResponse = await service({
          method: "get",
          url: `/user?likeOr[name]=${queryObject.query}&likeOr[email]=${queryObject.query}&page=1&perPage=10&where[organizer_id]=0`
        });

        console.log("xx", productsResponse.data.data)

        setSearchOptions(productsResponse.data.data)


      } catch (err) {
        console.log("ERROR")
      }

    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);


  const formik = useFormik({
    initialValues: {
      selectedProduct: null,
    },
    onSubmit: (values) => {
      // Handle form submission
      console.log(values);
    },
  });

  useEffect(() => {
    setSearchTerm(values?.[formikKeyName]?.label || "")
  }, [])


  const handleProductInputChange = (inputValue) => {
    // Update the selected product with the typed value
    console.log(inputValue);
    setSearchTerm(inputValue)
    // formik.setFieldValue('selectedProduct', { value: inputValue, label: inputValue });
  };

  return (
    <>
      <label htmlFor={formikKeyName}>{label}</label>
      <Select
        id={formikKeyName}
        name={formikKeyName}
        value={values[formikKeyName]}
        onChange={(selectedOption) => setFieldValue(formikKeyName, selectedOption)}
        onInputChange={handleProductInputChange}
        options={[{
          id: 0,
          name: ""
        }, ...searchOptions].map((product) => ({
          value: product.id,
          label: product.id == 0 ? 'Select User' : `#${product.id} || ${product.email} || ${product.name}`,
        }))}
        isSearchable
        placeholder="Search for a User..."
      />
    </>
  );
};

export default SearchUser;
