import useQueryString from "../../../hooks/useQueryString";
import { Button, DatePicker, message, Space, Table, Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { FileExcelOutlined, PrinterOutlined } from "@ant-design/icons";
import reactToText from "react-to-text";
import Flex from "../Flex";
import { useReactToPrint } from "react-to-print";

var isObj = function (a) {
    if (!!a && a.constructor === Object) {
        return true;
    }
    return false;
};

var _st = function (z, g) {
    return "" + (g != "" ? "[" : "") + z + (g != "" ? "]" : "");
};

var fromObject = function (params, skipobjects, prefix) {
    if (skipobjects === void 0) {
        skipobjects = false;
    }
    if (prefix === void 0) {
        prefix = "";
    }
    var result = "";
    if (typeof params != "object") {
        return prefix + "=" + encodeURIComponent(params) + "&";
    }
    for (var param in params) {
        var c = "" + prefix + _st(param, prefix);
        if (isObj(params[param]) && !skipobjects) {
            result += fromObject(params[param], false, "" + c);
        } else if (Array.isArray(params[param]) && !skipobjects) {
            params[param].forEach(function (item, ind) {
                result += fromObject(item, false, c + "[" + ind + "]");
            });
        } else {
            result += c + "=" + encodeURIComponent(params[param]) + "&";
        }
    }
    return result;
};

const { RangePicker } = DatePicker;

const PaginateTable = ({
    fetch,
    params,
    print,
    columns,
    customeQuery = {},
    csv = false,
    filterDates = false,
    ...props
}) => {
    const componentRef = useRef();
    const [list, setList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [onChangeParams, setOnChangeParams] = useState({
        pagination: {},
        filters: {},
        sorter: {},
    });
    const [startFilterDate, setStartFilterDate] = useState("");
    const [endFilterDate, setEndFilterDate] = useState("");
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const [query, setQuery] = params
        ? useQueryString({
            pagination: {
                pageSize: 5,
                current: 0,
                total: 0,
            },
            sort: {},
        })
        : useState({
            pagination: {
                pageSize: 5,
                current: 0,
                total: 0,
            },
            sort: {},
        });

    const onChangeWrapper = (pagination, filters, sorter, callbacks) => {
        if (typeof callbacks != "undefined") {
            onChange(
                onChangeParams.pagination,
                onChangeParams.filters,
                onChangeParams.sorter
            );
        } else {
            onChange(pagination, filters, sorter);
        }
    };

    const onChange = async (pagination, filters = {}, sorter = {}) => {
        try {
            setOnChangeParams(() => ({
                pagination,
                filters,
                sorter,
            }));
            const forUpdateQuery = {};
            const params = {
                page: pagination.current,
                perPage: pagination.pageSize,
            };
            if (
                (sorter?.order && sorter?.field) ||
                (query.sort?.order && query.sort?.field)
            ) {
                forUpdateQuery.sort = {
                    field: sorter?.field || query.sort?.field,
                    order: sorter?.order || query.sort?.order,
                };
                params.sort = {
                    field: forUpdateQuery.sort?.field,
                    order: forUpdateQuery.sort?.order == "ascend" ? "asc" : "desc",
                };
            }
            if (Object.entries(filters)?.length) {
                params.where = {};
                Object.entries(filters).forEach((val, ke) => {
                    if (val[1] != null) params.where[val[0]] = val[1][0];
                });
            }
            let datesQuery = {};
            if (startFilterDate && endFilterDate) {
                datesQuery = {
                    date: {
                        from: startFilterDate,
                        to: endFilterDate,
                    },
                };
            }

            setLoading(true);
            console.log("----------------->", customeQuery, params, {
                ...customeQuery,
                ...datesQuery,
                ...params,
            });
            const result = await fetch(
                fromObject({ ...customeQuery, ...datesQuery, ...params })
            );
            console.log(result);
            setLoading(false);

            setList(Object.entries(result?.data || {}).map((i) => i[1]));

            setQuery({
                ...query,
                pagination: {
                    ...query.pagination,
                    current: result?.current_page,
                    pageSize: result?.per_page,
                    total: result?.total,
                },
                // ...forUpdateQuery,
                // ...customeQuery
            });
        } catch (err) {
            console.log(err);
            // message.error("something went wrong");
            setLoading(false);
        }
    };

    useEffect(() => {
        onChange(query.pagination);
    }, []);

    useEffect(() => {
        if (Object.entries(customeQuery).length) {
            onChange(query.pagination);
        }
    }, [customeQuery]);

    useEffect(() => {
        if (startFilterDate && endFilterDate) {
            onChangeWrapper(null, null, null, true);
        }
    }, [startFilterDate, endFilterDate]);

    function parserOfColumnData(columns, item) {
        if (columns.length) {
            return columns.map((col) => {
                if (col.dataIndex && typeof col.render == "undefined") {
                    return item[col.dataIndex];
                } else if (typeof col.render != "undefined") {
                    return reactToText(col.render(item[col.dataIndex] || "", item));
                } else {
                    return "";
                }
            });
        }
        return [];
    }

    return (
        <>
            <div className="table-responsive">
                {true && (
                    <>
                        <Flex
                            alignItems="center"
                            justifyContent="between"
                            mobileFlex={false}
                        >
                            <div>
                                {csv && (
                                    <Button size="small" type="ghost" shape="circle">
                                        {/* <FileExcelOutlined /> Download */}
                                        <Tooltip placement="top" title={"Export"}>
                                            <CSVLink
                                                className="d-flex align-items-center justify-content-center"
                                                filename={(csv || "export") + ".csv"}
                                                data={[
                                                    columns.map((col) => col?.title || ""),
                                                    ...(list.map((item) => {
                                                        return parserOfColumnData(columns, item);
                                                    }) || []),
                                                ]}
                                            >
                                                <FileExcelOutlined />
                                            </CSVLink>
                                        </Tooltip>
                                    </Button>
                                )}
                                {(csv || print) && (
                                    <Button className="ml-2" onClick={handlePrint} size="small" type="ghost" shape="circle">
                                        <Tooltip placement="top" title={"Print"}>
                                            <PrinterOutlined />
                                        </Tooltip>
                                    </Button>
                                )}
                            </div>
                            <div className="">
                                {filterDates && (
                                    <Space direction="vertical" className="my-2" size={12}>
                                        <RangePicker
                                            allowClear
                                            onChange={function (value, formateString) {
                                                setStartFilterDate(() => formateString[0]);
                                                setEndFilterDate(() => formateString[1]);
                                                if (formateString[0] == "" && formateString[1] == "") {
                                                    setTimeout(() => {
                                                        onChangeWrapper(
                                                            onChangeParams.pagination,
                                                            onChangeParams.filters,
                                                            onChangeParams.sorter
                                                        );
                                                    }, 100);
                                                }
                                            }}
                                        />
                                    </Space>
                                )}
                            </div>
                        </Flex>
                    </>
                )}
                <div ref={componentRef}>
                    <Table
                        columns={columns}
                        dataSource={list}
                        pagination={{
                            ...query.pagination,
                            pageSizeOptions: [5, 10, 25, 50, -1],
                            showSizeChanger: true,
                        }}
                        loading={isLoading}
                        onChange={(pagination, filters, sorter) => {
                            onChangeWrapper(pagination, filters, sorter);
                        }}
                        rowKey="id"
                        {...props}
                    />
                </div>
            </div>
        </>
    );
};

export default PaginateTable;
