import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

import Sponser from "./sponser/reducer";
import User from "./user/reducer";
import Exibitor from "./exibitor";
import Gallery from "./gallery";
import Query from "./query";

import Brand from "./brand";
import PincodeCharge from "./pincodeCharge";
import Category from "./category";
import Product from "./product";
import Order from "./order";
import Newuser from "./new_user";
import Coupon from "./coupon";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  // contacts,
  Sponser,
  User,
  Exibitor,
  Gallery,
  Query,
  Brand,
  Category,
  Product,
  Order,
  Newuser,
  PincodeCharge,
  Coupon
});

export default rootReducer;
