import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import Dropzone from "react-dropzone"
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from "yup";
import Switch from "react-switch"
import { createCoupon, fetchSpecificCoupons, updateCoupon } from '../../store/actions'
import { message, Spin } from 'antd'

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      No
    </div>
  )
}

const OnSymbol = props => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      Yes
    </div>
  )
}

export class CouponOperations extends Component {
  static propTypes = {
    type: PropTypes.any
  }


  constructor(props) {
    super(props)
    this.state = {
      selectedFiles: [],
      isSubmiting: false
    }
  }


  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    )

    this.setState({ selectedFiles: files })
  }

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  componentDidMount() {
    const { match } = this.props;
    if (this.props.type == "EDIT") {
      console.log('Getting ID', match.params.id)
      this.props.fetchSpecificCoupons(match.params.id)
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Coupons" breadcrumbItem={`${this.props.type == "ADD" ? 'Add' : 'Update'} Coupon`} />

            <Row>
              <Col xs="12">
                <Spin spinning={this.props.isLoading}>
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">Basic Information</CardTitle>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          ...(this.props.type == "EDIT" ? this.props.coupon : {})
                        }}
                        validationSchema={
                          Yup.object().shape({
                            code: Yup.string().required('Code is required'),
                            name: Yup.string().required('Name is required'),
                            discount_in_percentage: Yup.number()
                              .min(0, 'Value must be greater than or equal to 0')
                              .max(100, 'Value must be less than or equal to 100')
                              .required('Discount is required'),
                            // .test('endsWithPercent', 'Field must end with "%"', value => {
                            //   if (!value) {
                            //     // If the value is not provided, let other validations (like required) handle it
                            //     return true;
                            //   }
                            //   return value.endsWith('%');
                            // }),
                            discount_on_order_above: Yup.string().required('Discount is required'),
                            discount_upto: Yup.string().required('Discount Upto is required'),
                            valid_upto: Yup.string().required('Name is required'),
                            no_of_uses: Yup.string().required('Number of Usage is required'),
                            // devlivery_charge: Yup.string().required('Delivery Charges is required'),
                          })}
                        onSubmit={values => {
                          const that = this;
                          this.setState({ isSubmiting: true })
                          values.is_active = values.is_active ? 1 : 0;
                          values.type = 'btob';
                          values.for_specific_users = 0;
                          let apiSubmit = null;
                          if (this.props.type == "EDIT") {
                            values._method = 'patch'
                            this.props.updateCoupon([this.props.match.params.id, values])
                          } else {
                            this.props.createCoupon(values)
                          }
                        }}
                      >
                        {({ errors, status, touched, setFieldValue, validateForm, values, }) => (
                          <Form className="form-horizontal">
                            <Row>

                              <Col sm="6">
                                <div className="mb-3">
                                  <Label for="name" className="form-label">
                                    Coupon Code *
                                  </Label>
                                  <Field
                                    name="code"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.code
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage name="code" render={msg => <div className="error">{msg}</div>} />
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label for="name" className="form-label">
                                    Coupon Name *
                                  </Label>
                                  <Field
                                    name="name"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.name
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage name="name" render={msg => <div className="error">{msg}</div>} />
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label for="name" className="form-label">
                                    Discount in Percentage *
                                  </Label>
                                  <Field
                                    name="discount_in_percentage"
                                    type="number"
                                    className={
                                      "form-control" +
                                      (errors.discount_in_percentage
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage name="discount_in_percentage" render={msg => <div className="error">{msg}</div>} />
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label for="name" className="form-label">
                                    Discount on order above *
                                  </Label>
                                  <Field
                                    name="discount_on_order_above"
                                    type="number"
                                    className={
                                      "form-control" +
                                      (errors.discount_on_order_above
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage name="discount_on_order_above" render={msg => <div className="error">{msg}</div>} />
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label for="name" className="form-label">
                                    Dicount Upto *
                                  </Label>
                                  <Field
                                    name="discount_upto"
                                    type="number"
                                    className={
                                      "form-control" +
                                      (errors.discount_upto
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage name="discount_upto" render={msg => <div className="error">{msg}</div>} />
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label for="name" className="form-label">
                                    No of usages *
                                  </Label>
                                  <Field
                                    name="no_of_uses"
                                    type="number"
                                    className={
                                      "form-control" +
                                      (errors.no_of_uses
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage name="no_of_uses" render={msg => <div className="error">{msg}</div>} />
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label for="name" className="form-label">
                                    Valid Upto *
                                  </Label>
                                  <Field
                                    name="valid_upto"
                                    type="date"
                                    className={
                                      "form-control" +
                                      (errors.valid_upto
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage name="valid_upto" render={msg => <div className="error">{msg}</div>} />
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label className='pr-3'>Coupon Status</Label> <br />
                                  <Switch
                                    uncheckedIcon={<Offsymbol />}
                                    className="me-1 mb-sm-8 mb-2"
                                    checkedIcon={<OnSymbol />}
                                    onColor="#626ed4"
                                    onChange={() =>
                                      setFieldValue('is_active', values.is_active ? !true : !false)
                                    }
                                    checked={values.is_active ? true : false}
                                  />
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label className='pr-3'>For Specific Users</Label> <br />
                                  <Switch
                                    uncheckedIcon={<Offsymbol />}
                                    className="me-1 mb-sm-8 mb-2"
                                    checkedIcon={<OnSymbol />}
                                    onColor="#626ed4"
                                    onChange={() =>
                                      setFieldValue('for_specific_users', values.for_specific_users ? !true : !false)
                                    }
                                    checked={values.for_specific_users ? true : false}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <div className="d-flex flex-wrap gap-2 mt-4">
                              <Button
                                type="submit"
                                color="primary"
                                disabled={this.props.isLoading}
                              >

                                {this.props.isLoading ? ' Saving....' : 'Save Changes'}
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Formik>

                    </CardBody>
                  </Card>
                </Spin>

              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment >
    )
  }
}

const mapStateToProps = (state) => ({
  coupon: state.Coupon.single,
  isLoading: state.Coupon.isLoading
})

const mapDispatchToProps = dispatch => ({
  createCoupon: payload => dispatch(createCoupon(payload)),
  updateCoupon: (payload) => dispatch(updateCoupon(payload)),
  fetchSpecificCoupons: payload => dispatch(fetchSpecificCoupons(payload))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CouponOperations))

