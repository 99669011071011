import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import axios from 'axios';
import Select from 'react-select';

const ProductForm = ({
  values,
  setFieldValue,
  label = "",
  formikKeyName = "selectedProduct"
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchOptions, setSearchOptions] = useState([])

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      try {
        const queryObject = {
          query: searchTerm,
          page: 1,
          perPage: 10
        }

        const productsResponse = await axios.get(`https://auscorp.tectrionix.com/backend/public/api/v1/btob/product_search?query=${searchTerm}&page=1&perPage=10`);

        setSearchOptions(productsResponse.data.data.data)


      } catch (err) {
        console.log("ERROR")
      }

    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);


  const formik = useFormik({
    initialValues: {
      selectedProduct: null,
    },
    onSubmit: (values) => {
      // Handle form submission
      console.log(values);
    },
  });

  useEffect(() => {
    setSearchTerm(values?.[formikKeyName]?.label || "")
  }, [])

  // useEffect(() => {
  //   const fetchProducts = async () => {
  //     try {
  //       const response = await axios.get(
  //         `https://your-api-endpoint/products?search=${formik.values.selectedProduct.label}`
  //       );
  //       setProductOptions(response.data);
  //     } catch (error) {
  //       console.error('Error fetching products:', error);
  //     }
  //   };

  //   if (formik.values.selectedProduct) {
  //     fetchProducts();
  //   } else {
  //     setProductOptions([]);
  //   }
  // }, [formik.values.selectedProduct]);

  const handleProductInputChange = (inputValue) => {
    // Update the selected product with the typed value
    console.log(inputValue);
    setSearchTerm(inputValue)
    // formik.setFieldValue('selectedProduct', { value: inputValue, label: inputValue });
  };

  return (
    <>
      <label htmlFor={formikKeyName}>{label}</label>
      <Select
        id={formikKeyName}
        name={formikKeyName}
        value={values[formikKeyName]}
        onChange={(selectedOption) => setFieldValue(formikKeyName, selectedOption)}
        onInputChange={handleProductInputChange}
        options={[{id: 0, name: "Select product" },...searchOptions].map((product) => ({
          value: product.id,
          label: product.name,
        }))}
        isSearchable
        placeholder="Search for a product..."
      />
    </>
  );
};

export default ProductForm;
