import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import classNames from "classnames";

// Import FecthAPI
import service from "../../helpers/Api/FetchInterceptor";
import Lightbox from "react-image-lightbox";
import { deleteBrand, fetchBrands } from "../../store/actions";
import PaginateTableSaga from "../../components/UI/Table/TableSaga";
import { Popconfirm, Tag, Table, Dropdown } from "antd";
import { MoreOutlined, EditOutlined, DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons"
import moment from "moment";

function Test(props) {
  const [visible, setVisible] = useState(false)
  return (
    <React.Fragment>
      <img src={props.row.logo} style={{ width: '200px' }} onClick={() => setVisible(true)} />
      {visible && (
        <Lightbox
          mainSrc={props.row.logo}
          enableZoom={false}
          imageCaption={props.row.company_name}
          onCloseRequest={() => { setVisible(false) }}
        />
      )}
    </React.Fragment>
  )
}

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customQuery: {},
      tableList: [],
      tablePagination: {
        pageSize: 5,
        current: 0,
        total: 0,
      },
      tableLoading: false
    };

    this.tableColumns = [
      {
        title: "ID",
        dataIndex: "id",
        sorter: true,
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: true,
      },
      {
        title: "Email",
        dataIndex: "email",
        sorter: true,
      },
      {
        title: "Phone",
        dataIndex: "phone",
        sorter: true,
      },
      {
        title: "Message",
        dataIndex: "content",
        sorter: true,
      },
      {
        title: "Status",
        dataIndex: "is_readed",
        render: (text, row) => {
          return (
            <>
              {row.is_readed == 1 ? (
                <Tag color={"green"}>Readed</Tag>
              ) : (
                <Tag color={"red"}>Pending</Tag>
              )}
            </>
          );
        },
        sorter: true,
      },
      {
        title: "Time",
        dataIndex: "created_at",
        render: (text, row) => {
          return (
            <>
              <Tag>{moment(row.created_at).format("YYYY-MM-DD HH:mm A")}</Tag>
            </>
          );
        },
        sorter: true,
      },
      {
        title: "Action",
        dataIndex: "",
        sorter: false,
        render: (_, row) => (
          <>
            <div className="d-flex gap-3">
              {!row.is_readed && (
                <Popconfirm
                  title="Are you sure?"
                  className="text-success cursor-pointer"
                  description="You sure you readed ?"
                  onConfirm={() => this.confirmRead(row.id)}
                  onOpenChange={() => console.log('open change')}
                >
                  <i
                    className="mdi mdi-check font-size-18 cursor-pointer"
                    id="deletetooltip"
                  // onClick={() => this.onClickDelete(user)}
                  ></i>
                </Popconfirm>
              )}
            </div>
          </>
        )
      },
    ];

  }


  async tableFetchApi(params) {
    const that = this;
    try {
      this.setState({
        tableLoading: true
      })
      let result = await service.get('/query_list' + "?" + params);

      const apiData = result.data;

      this.setState({
        tableLoading: false,
        tableList: apiData.data,
        tablePagination: {
          pageSize: apiData?.per_page,
          current: apiData?.current_page,
          total: apiData?.total,
        }
      })
      return result.data;
    } catch (err) {
      console.log(that, "THIS")
      this.setState({
        tableLoading: false
      })
    }
  }

  confirmRead(id) {
    this.setState({
      tableLoading: true
    })
    service({
      method: "GET",
      url: '/query_list' + "/" + id,
    })
      .then(res => {
        const result = res.data;
        this.setState({
          tableLoading: false,
          tableList: this.state.tableList.map(i => i.id != id ? i : { ...i, is_readed: 1 })
        })
      })
      .catch(err => {
        this.setState({
          tableLoading: false,
        })
      })
  }

  componentDidMount() {
    // this.props.fetchBrand("");
  }

  componentDidUpdate(prevProps) {
  }

  render() {
    //meta title
    document.title = "Brands";

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("Dashboards")}
              breadcrumbItem={this.props.t("Contact Query")}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row className="mb-4">
                      <Col sm="4">
                      </Col>
                      <Col sm="8">
                      </Col>
                    </Row>
                    <PaginateTableSaga
                      fetch={this.tableFetchApi.bind(this)}
                      isLoading={this.state.tableLoading}
                      columns={this.tableColumns}
                      list={this.state.tableList}
                      pagination={this.state.tablePagination}
                      customeQuery={this.state.customQuery}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* Render Breadcrumb */}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Contact.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = ({ Brand }) => {
  return ({
    // brandState: Brand,
    // query_list: Brand.list,
  })
};

const mapDispatchToProps = dispatch => ({
  // fetchBrand: (params) => dispatch(fetchBrands(params)),
  // deleteBrand: (prams) => dispatch(deleteBrand(prams)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Contact));
