import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { history } from '../../App';
import service from '../../helpers/Api/FetchInterceptor';

const API_URL = "/products"

// Define a thunk function for fetching data
export const fetchProducts = createAsyncThunk(
  'products/fetchProducts',
  async (params) => {
    let result = await service.get("/products-parent"  + "?" + params);
    return result.data;
  }
);

export const fetchProductsOnlyParent = createAsyncThunk(
  'products/fetchProductsOnlyParent',
  async (params) => {
    let result = await service.get("/products-parent" + "?" + params);
    return result.data;
  }
);

// Define a thunk function for creating data
export const fetchSpecificProducts = createAsyncThunk(
  'products/fetchSpecificProducts',
  async (id) => {
    const result = await service.get(API_URL + "/" + id);
    return result.data;
  }
);


// Define a thunk function for creating data
export const createProduct = createAsyncThunk(
  'products/createProduct',
  async (newProduct) => {
    const formData = new FormData();
    Object.keys(newProduct).forEach(key => formData.append(key, newProduct[key]));
    let result = await service({
      method: "post",
      url: API_URL,
      data: formData
    });
    return result.data;
  }
);

export const createProductImage = createAsyncThunk(
  'products/createProductImage',
  async (newProduct) => {
    const formData = new FormData();
    Object.keys(newProduct).forEach(key => formData.append(key, newProduct[key]));
    let result = await service({
      method: "post",
      url: "/product_images",
      data: formData
    });
    return result.data;
  }
);

// Define a thunk function for updating data
export const updateProduct = createAsyncThunk(
  'products/updateProduct',
  async (updatedProduct) => {
    const [id, values] = updatedProduct;
    console.log("UPDATE", updatedProduct)
    const formData = new FormData();
    Object.keys(values).forEach(key => formData.append(key, values[key]));
    let result = await service({
      url: API_URL + "/" + id,
      method: "POST",
      data: formData
    });
    return result.data;
  }
);

// Define a thunk function for deleting data
export const deleteProduct = createAsyncThunk(
  'products/deleteProduct',
  async (id) => {
    await service.delete(`${API_URL}/${id}`);
    return id;
  }
);

export const deleteProductImage = createAsyncThunk(
  'products/deleteProductImage',
  async (id) => {
    await service.delete(`${"/product_images"}/${id}`);
    return id;
  }
);

// Define a slice with initial state and reducers
const productsSlice = createSlice({
  name: 'products',
  initialState: {
    single: {},
    list: [],
    pagination: {
      pageSize: 5,
      current: 0,
      total: 0,
    },
    isLoading: false,
    sort: {},
    error: null,
  },
  reducers: {
    deleteProductProductDetailsInsideArray: (state, action) => {
      console.log("START DELETE", action.payload)
      state.list.forEach((val, i) => {
        if (val.product_details?.find(v => v.id == action.payload)) {
          console.log("FIND DELETE", val, "INDEX: ", i)
          state.list[i] = {
            ...state.list[i],
            product_details: val.product_details.filter(x => x.id != action.payload)
          }
        }
      });
      state.list = [...state.list]
    },
  },
  extraReducers: (builder) => {
    // Add reducers for fetching data
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        const data = action.payload
        state.list = data?.data
        state.pagination = {
          pageSize: data?.per_page,
          current: data?.current_page,
          total: data?.total,
        }
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(fetchProductsOnlyParent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchProductsOnlyParent.fulfilled, (state, action) => {
        state.isLoading = false;
        const data = action.payload
        state.list = data?.data
        state.pagination = {
          pageSize: data?.per_page,
          current: data?.current_page,
          total: data?.total,
        }
      })
      .addCase(fetchProductsOnlyParent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Add reducers for fetching data
    builder
      .addCase(fetchSpecificProducts.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSpecificProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        const data = action.payload
        state.single = { ...data?.product, relatedTo: data?.relatedTo, relatedTo: data?.relatedTo, parentProduct: data?.parent_product }
      })
      .addCase(fetchSpecificProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Add reducers for creating data
    builder
      .addCase(createProduct.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list.push(action.payload);
        history.push("/products")
      })
      .addCase(createProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(createProductImage.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createProductImage.fulfilled, (state, action) => {
        state.isLoading = false;
        console.log(action.payload);
        state.single.images = [...state.single.images, action.payload?.product_image || {}]
        // state.list.push(action.payload);
        // history.push("/products")
      })
      .addCase(createProductImage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Add reducers for updating data
    builder
      .addCase(updateProduct.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.list.findIndex(product => product.id === action.payload.id);
        if (index !== -1) {
          state.list[index] = action.payload;
        }
        history.push("/products")
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(deleteProduct.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list = state.list.filter(product => product.id !== action.payload);
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(deleteProductImage.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteProductImage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.single.images = state.single?.images?.filter(img => img.id !== action.payload) || [];
      })
      .addCase(deleteProductImage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  }
})

// Export the action creators and the reducer
export const { deleteProductProductDetailsInsideArray } = productsSlice.actions;
export default productsSlice.reducer;
