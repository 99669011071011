/* TASKS */
export const USER_LOADING_1 = 'USER_LOADING_1'
export const USER_LOADING_2 = 'USER_LOADING_2'
export const GET_USERS = 'GET_USERS'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FAIL = 'GET_USERS_FAIL'
export const ADD_USER = 'ADD_USER'
export const GET_SPECIFIC_USER = 'GET_SPECIFIC_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const USER_TEST = 'USER_TEST'
