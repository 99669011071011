import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import classNames from "classnames";

// Import FecthAPI
import service from "../../helpers/Api/FetchInterceptor";
import Lightbox from "react-image-lightbox";
import { deleteProduct, fetchProducts } from "../../store/actions";
import PaginateTableSaga from "../../components/UI/Table/TableSaga";
import { Popconfirm, Tag, Table, Dropdown } from "antd";
import { MoreOutlined, EditOutlined, DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons"

function Test(props) {
  const [visible, setVisible] = useState(false)
  return (
    <React.Fragment>
      <img src={props.row.logo} style={{ width: '200px' }} onClick={() => setVisible(true)} />
      {visible && (
        <Lightbox
          mainSrc={props.row.logo}
          enableZoom={false}
          imageCaption={props.row.company_name}
          onCloseRequest={() => { setVisible(false) }}
        />
      )}
    </React.Fragment>
  )
}

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customQuery: {}
    };

    this.tableColumns = [
      {
        title: "ID",
        dataIndex: "id",
        sorter: true,
      },
      {
        title: "Thumbnail",
        dataIndex: "thumbnail",
        sorter: true,
        render: (text) => (<img style={{
          width: "100px"
        }} src={text}></img>)
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: true,
        render: (text, row) => (
          <>
            <h6>
              {text}
            </h6>
            <span>{row.product_code}</span>
          </>
        )
      },
      {
        title: "Product Code",
        dataIndex: "product_code",
        sorter: true,
      },
      {
        title: "Slug",
        dataIndex: "slug",
        sorter: true,
      },

      // {
      //   title: "B-to-C Price",
      //   dataIndex: "b_to_c_price",
      //   sorter: true,
      // },
      // {
      //   title: "B-to-B Price",
      //   dataIndex: "b_to_b_price",
      //   sorter: true,
      // },
      // {
      //   title: "Total Product",
      //   dataIndex: "",
      //   render: (row) => row?.product_details?.length || 0
      // },
      {
        title: "Featured",
        dataIndex: "is_featured",
        render: (text, row) => {
          return (
            <>
              {row.is_featured == 1 ? (
                <Tag color={"green"}>Yes</Tag>
              ) : (
                <Tag color={"red"}>No</Tag>
              )}
            </>
          );
        },
        sorter: true,
      },
      {
        title: "Status",
        dataIndex: "is_active",
        render: (text, row) => {
          return (
            <>
              {row.is_active == 1 ? (
                <Tag color={"green"}>Active</Tag>
              ) : (
                <Tag color={"red"}>In Active</Tag>
              )}
            </>
          );
        },
        sorter: true,
      },
      {
        title: "Action",
        dataIndex: "",
        sorter: false,
        render: (_, row) => (
          <>
            <div className="d-flex gap-3">
              <Link className="text-success mr-2" to={`/products/${row.id}/edit`}>
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                // onClick={() => this.handleUserClick(user)}
                ></i>
              </Link>
              <Link className="text-info mr-2" to={`/products/associated/${row.id}`}>
                <i
                  className="mdi mdi-eye font-size-18"
                  id="edittooltip"
                // onClick={() => this.handleUserClick(user)}
                ></i>
              </Link>
              <Link>
                <Popconfirm
                  title="Are you sure?"
                  className="center-flex-y"
                  description="You want to Delete"
                  onConfirm={() => this.props.deleteProduct(row.id).payload}
                  onOpenChange={() => console.log('open change')}
                >
                  <i
                    className="mdi mdi-delete font-size-18 text-danger ml-2"
                    id="deletetooltip"
                  // onClick={() => this.onClickDelete(user)}
                  ></i>
                </Popconfirm>
              </Link>
            </div>
          </>
        )
      },
    ];

  }



  async fetchApi(params) {
    let result = await service.get('/products' + "?" + params);
    return result.data;
  }

  componentDidMount() {
    // this.props.fetchProduct("");
  }

  componentDidUpdate(prevProps) {
  }

  render() {
    //meta title
    document.title = "Products";

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("Dashboards")}
              breadcrumbItem={this.props.t("Products")}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row className="mb-4">
                      <Col sm="4">
                      </Col>
                      <Col sm="8">
                        <div className="text-sm-end">
                          <Link to={'/products/add'}>
                            <Button
                              color="primary"
                              className="font-16 btn-block btn btn-primary"
                            >
                              <i className="mdi mdi-plus-circle-outline me-1" />
                              Create New Product
                            </Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                    <PaginateTableSaga
                      fetch={this.props.fetchProduct}
                      isLoading={this.props.productState.isLoading}
                      columns={this.tableColumns}
                      list={this.props.products}
                      pagination={this.props.productState.pagination}
                      customeQuery={this.customQuery}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* Render Breadcrumb */}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Products.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = ({ Product }) => {
  return ({
    productState: Product,
    products: Product.list,
  })
};

const mapDispatchToProps = dispatch => ({
  fetchProduct: (params) => dispatch(fetchProducts(params)),
  deleteProduct: (prams) => dispatch(deleteProduct(prams)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Products));
