import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import classNames from "classnames";

// Import FecthAPI
import service from "../../helpers/Api/FetchInterceptor";
import Lightbox from "react-image-lightbox";
import { deleteBrand, fetchBrands } from "../../store/actions";
import PaginateTableSaga from "../../components/UI/Table/TableSaga";
import { Popconfirm, Tag, Table, Dropdown } from "antd";
import { MoreOutlined, EditOutlined, DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons"

function Test(props) {
  const [visible, setVisible] = useState(false)
  return (
    <React.Fragment>
      <img src={props.row.logo} style={{ width: '200px' }} onClick={() => setVisible(true)} />
      {visible && (
        <Lightbox
          mainSrc={props.row.logo}
          enableZoom={false}
          imageCaption={props.row.company_name}
          onCloseRequest={() => { setVisible(false) }}
        />
      )}
    </React.Fragment>
  )
}

class Banners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customQuery: {},
      tableList: [],
      tablePagination: {
        pageSize: 5,
        current: 0,
        total: 0,
      },
      tableLoading: false
    };

    this.tableColumns = [
      {
        title: "ID",
        dataIndex: "id",
        sorter: true,
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: true,
      },
      {
        title: "Image",
        dataIndex: "banner",
        sorter: true,
        render: (text) => (<img style={{
          width: "100px"
        }} src={text}></img>)
      },
      {
        title: "URL",
        dataIndex: "url",
        sorter: true,
      },
      {
        title: "Priority",
        dataIndex: "priority",
        sorter: true,
      },
      // {
      //   title: "Total Brand",
      //   dataIndex: "",
      //   render: (row) => row?.brand_details?.length || 0
      // },
      {
        title: "Status",
        dataIndex: "is_active",
        render: (text, row) => {
          return (
            <>
              {row.is_active == 1 ? (
                <Tag color={"green"}>Active</Tag>
              ) : (
                <Tag color={"red"}>In Active</Tag>
              )}
            </>
          );
        },
        sorter: true,
      },
      {
        title: "Action",
        dataIndex: "",
        sorter: false,
        render: (_, row) => (
          <>
            <div className="d-flex gap-3">
              <Link className="text-success" to={`/banners/${row.id}/edit`}>
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                // onClick={() => this.handleUserClick(user)}
                ></i>
              </Link>
              <Popconfirm
                title="Are you sure?"
                className="text-danger cursor-pointer"
                description="You want to Delete"
                onConfirm={() => this.deleteAPI(row.id)}
                onOpenChange={() => console.log('open change')}
              >
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                // onClick={() => this.onClickDelete(user)}
                ></i>
              </Popconfirm>
            </div>
          </>
        )
      },
    ];

  }


  async tableFetchApi(params) {
    const that = this;
    try {
      this.setState({
        tableLoading: true
      })
      let result = await service.get('/banners' + "?" + params);

      const apiData = result.data;

      this.setState({
        tableLoading: false,
        tableList: apiData.data,
        tablePagination: {
          pageSize: apiData?.per_page,
          current: apiData?.current_page,
          total: apiData?.total,
        }
      })
      return result.data;
    } catch (err) {
      console.log(that, "THIS")
      this.setState({
        tableLoading: false
      })
    }
  }

  deleteAPI(id) {
    this.setState({
      tableLoading: true
    })
    service({
      method: "DELETE",
      url: '/banners' + "/" + id,
    })
      .then(res => {
        const result = res.data;
        this.setState({
          tableLoading: false,
          tableList: this.state.tableList.filter(i => i.id != id)
        })
      })
      .catch(err => {
        this.setState({
          tableLoading: false,
        })
      })
  }

  componentDidMount() {
    // this.props.fetchBrand("");
  }

  componentDidUpdate(prevProps) {
  }

  render() {
    //meta title
    document.title = "Banners";

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("Dashboards")}
              breadcrumbItem={this.props.t("Banners")}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row className="mb-4">
                      <Col sm="4">
                      </Col>
                      <Col sm="8">
                        <div className="text-sm-end">
                          <Link to={'/banners/add'}>
                            <Button
                              color="primary"
                              className="font-16 btn-block btn btn-primary"
                            >
                              <i className="mdi mdi-plus-circle-outline me-1" />
                              Create New Banner
                            </Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                    <PaginateTableSaga
                      fetch={this.tableFetchApi.bind(this)}
                      isLoading={this.state.tableLoading}
                      columns={this.tableColumns}
                      list={this.state.tableList}
                      pagination={this.state.tablePagination}
                      customeQuery={this.state.customQuery}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* Render Breadcrumb */}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Banners.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = ({ Brand }) => {
  return ({
    // brandState: Brand,
    // banners: Brand.list,
  })
};

const mapDispatchToProps = dispatch => ({
  // fetchBrand: (params) => dispatch(fetchBrands(params)),
  // deleteBrand: (prams) => dispatch(deleteBrand(prams)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Banners));
