import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import Dropzone from "react-dropzone"
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from "yup";
import Switch from "react-switch"
import { createOrder, fetchSpecificOrders, updateOrder } from '../../store/actions'
import { message, Spin } from 'antd'

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      No
    </div>
  )
}

const OnSymbol = props => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      Yes
    </div>
  )
}

export class OrderOperations extends Component {
  static propTypes = {
    type: PropTypes.any
  }


  constructor(props) {
    super(props)
    this.state = {
      selectedFiles: [],
      isSubmiting: false,
      rows: []
    }
  }


  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    )

    this.setState({ selectedFiles: files })
  }

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  componentDidMount() {
    const { match } = this.props;
    if (this.props.type == "EDIT") {
      console.log('Getting ID', match.params.id)
      this.props.fetchSpecificOrders(match.params.id)
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Orders" breadcrumbItem={`${this.props.type == "ADD" ? 'Add' : 'Update'} Order`} />

            <Row>
              <Col xs="12">
                <Spin spinning={this.props.isLoading}>
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">Basic Information</CardTitle>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          ...(this.props.type == "EDIT" ? this.props.order : {})
                        }}
                        validationSchema={
                          Yup.object().shape({
                            name: Yup.string().required('Name is required'),
                            description: Yup.string().required('Description is required'),
                            icon: Yup.mixed().required('Icon is required')
                          })}
                        onSubmit={values => {
                          const that = this;
                          this.setState({ isSubmiting: true })
                          values.is_active = values.is_active ? 1 : 0;
                          let apiSubmit = null;
                          if (this.props.type == "EDIT") {
                            if (!(values.icon instanceof File)) {
                              delete values['icon']
                            }
                            values._method = 'patch'
                            this.props.updateOrder([this.props.match.params.id, values])
                          } else {
                            this.props.createOrder(values)
                          }
                        }}
                      >
                        {({ errors, status, touched, setFieldValue, validateForm, values, }) => (
                          <Form className="form-horizontal">
                            <Row>

                              <Col sm="6">
                                <div className="mb-3">
                                  <Label for="name" className="form-label">
                                    Order Name *
                                  </Label>
                                  <Field
                                    name="name"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.name
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage name="name" render={msg => <div className="error">{msg}</div>} />
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label for="text" className="form-label">
                                    Order description *
                                  </Label>
                                  <Field
                                    name="description"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.description
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage name="description" render={msg => <div className="error">{msg}</div>} />
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <div>
                                    <Label for="text" className="form-label">
                                      Icon *
                                    </Label>
                                    {/* <Field type="file" className={
                                      "form-control" +
                                      (errors.icon
                                        ? " is-invalid"
                                        : "")
                                    }
                                    // onChange={(event) => {
                                    //   setFieldValue("icon", event.currentTarget.files[0]);
                                    // }}
                                    /> */}
                                    <input type="file" className='form-control' onChange={(event) => {
                                      setFieldValue("icon", event.currentTarget.files[0]);
                                    }} />
                                    <ErrorMessage name="icon" render={msg => <div className="error">{msg}</div>} />
                                  </div>
                                </div>
                                {
                                  !(values.image instanceof File) ? (<>
                                    {console.log(values, "VALUES")}
                                    <img style={{
                                      width: "200px"
                                    }} src={values.icon} alt="" />
                                  </>) : (<></>)
                                }
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label className='pr-3'>Order Status</Label> <br />
                                  <Switch
                                    uncheckedIcon={<Offsymbol />}
                                    className="me-1 mb-sm-8 mb-2"
                                    checkedIcon={<OnSymbol />}
                                    onColor="#626ed4"
                                    onChange={() =>
                                      setFieldValue('is_active', values.is_active ? !true : !false)
                                    }
                                    checked={values.is_active ? true : false}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <div className="d-flex flex-wrap gap-2 mt-4">
                              <Button
                                type="submit"
                                color="primary"
                                disabled={this.props.isLoading}
                              >

                                {this.props.isLoading ? ' Saving....' : 'Save Changes'}
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Formik>

                    </CardBody>
                  </Card>
                </Spin>

              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment >
    )
  }
}

const mapStateToProps = (state) => ({
  order: state.Order.single,
  isLoading: state.Order.isLoading
})

const mapDispatchToProps = dispatch => ({
  createOrder: payload => dispatch(createOrder(payload)),
  updateOrder: (payload) => dispatch(updateOrder(payload)),
  fetchSpecificOrders: payload => dispatch(fetchSpecificOrders(payload))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderOperations))

