import React from "react"
import { Redirect } from "react-router-dom"
// User profile
import UserProfile from "../pages/Authentication/UserProfile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Pages
// import Dashboard from "../pages/Dashboard/index"
import Users from "../pages/Users"
import UserOperations from "../pages/Users/UserOperations"
import Gallary from "../pages/Gallary"
import GallaryOperations from "../pages/Gallary/GallaryOperations"
// import Event from "../pages/Event"
// import EventOperations from "../pages/Event/EventOperations"
// import EventDetailsOperations from "../pages/EventDetails/EventDetailsOperations"
import Query from "../pages/Query"
import Brand from "pages/Brand"
import BrandOperations from "pages/Brand/BrandOperations"
import Category from "pages/Category"
import CategoryOperations from "pages/Category/CategoryOperations"
import Product from "pages/Product"
import ParentProduct from "pages/Product/onlyParentProducts"
import ProductOperations from "pages/Product/ProductOperations"
import Order from "pages/Order"
import OrderOperations from "pages/Order/OrderOperations"
import OrderDetails from "pages/Order/OrderDetails"
import OrderCreate from "pages/Order/OrderCreate"
import Newuser from "pages/Newuser"
import NewuserOperations from "pages/Newuser/NewuserOperations"
import PincodeCharge from "pages/PincodeCharge"
import PincodeChargeOperations from "pages/PincodeCharge/PincodeChargeOperations"
import ProductAssociated from "pages/Product/ProductAssociated"
import Coupon from "pages/Coupon"
import CouponOperations from "pages/Coupon/CouponOperations"
import Testimonial from "pages/Testimonial"
import TestimonialOperations from "pages/Testimonial/TestimonialOperations"
import Contact from "pages/Contact"
import Banner from "pages/Banner"
import BannerOperations from "pages/Banner/BannerOperations"
import CouponForSpecificUser from "pages/Coupon/CouponForSpecificUser"
import CouponForSpecificUserOperations from "pages/Coupon/CouponForSpecificUserOperations"
import Team from "pages/Team"
import TeamOperations from "pages/Team/TeamOperations"

const authProtectedRoutes = [
  // { path: "/dashboard", component: Dashboard },

  // { path: "/users", component: Users},
  // { path: "/users/add", component: (props) => <UserOperations type={'ADD'} />},
  // { path: "/users/:id/edit", component: (props) => <UserOperations type={'EDIT'} />},


  { path: "/users", component: Newuser},
  { path: "/users/add", component: (props) => <NewuserOperations type={'ADD'} />},
  { path: "/users/:id/edit", component: (props) => <NewuserOperations type={'EDIT'} />},

  // { path: "/events", component: Event},
  // { path: "/events/add", component: (props) => <EventOperations type={'ADD'} />},
  // { path: "/events/:id/edit", component: (props) => <EventOperations type={'EDIT'} />},
  
  // { path: "/event-details/:eventId/add", component: (props) => <EventDetailsOperations type={'ADD'} />},
  // { path: "/event-details/:eventId/:id/edit", component: (props) => <EventDetailsOperations type={'EDIT'} />},
  
  { path: "/galleries", component: Gallary},

  { path: "/brands", component: Brand},
  { path: "/brands/add", component: (props) => <BrandOperations type={'ADD'} />},
  { path: "/brands/:id/edit", component: (props) => <BrandOperations type={'EDIT'} />},

  { path: "/teams", component: Team},
  { path: "/teams/add", component: (props) => <TeamOperations type={'ADD'} />},
  { path: "/teams/:id/edit", component: (props) => <TeamOperations type={'EDIT'} />},
  
  { path: "/contact-query", component: Contact},

  { path: "/testimonials", component: Testimonial},
  { path: "/testimonials/add", component: (props) => <TestimonialOperations type={'ADD'} />},
  { path: "/testimonials/:id/edit", component: (props) => <TestimonialOperations type={'EDIT'} />},

  { path: "/banners", component: Banner},
  { path: "/banners/add", component: (props) => <BannerOperations type={'ADD'} />},
  { path: "/banners/:id/edit", component: (props) => <BannerOperations type={'EDIT'} />},

  { path: "/pincodeCharges", component: PincodeCharge},
  { path: "/pincodeCharges/add", component: (props) => <PincodeChargeOperations type={'ADD'} />},
  { path: "/pincodeCharges/:id/edit", component: (props) => <PincodeChargeOperations type={'EDIT'} />},

  { path: "/coupon", component: Coupon},
  { path: "/coupon/add", component: (props) => <CouponOperations type={'ADD'} />},
  { path: "/coupon/:id/edit", component: (props) => <CouponOperations type={'EDIT'} />},

  { path: "/coupon-users", component: (props) => <CouponForSpecificUser  />},
  { path: "/coupon-users/add", component: (props) => <CouponForSpecificUserOperations type={'ADD'} />},
  { path: "/coupon-users/:id/edit", component: (props) => <CouponForSpecificUserOperations type={'EDIT'} />},

  { path: "/categories", component: Category},
  { path: "/categories/add", component: (props) => <CategoryOperations type={'ADD'} />},
  { path: "/categories/:id/edit", component: (props) => <CategoryOperations type={'EDIT'} />},

  { path: "/products", component: Product},
  { path: "/parent-products", component: ParentProduct},
  { path: "/products/add", component: (props) => <ProductOperations type={'ADD'} />},
  { path: "/products/associated/:id", component: (props) => <ProductAssociated/>},
  { path: "/products/:id/edit", component: (props) => <ProductOperations type={'EDIT'} />},

  { path: "/orders", component: Order},
  { path: "/orders/create", component: (props) => <OrderCreate />},
  { path: "/orders/add", component: (props) => <OrderOperations type={'ADD'} />},
  { path: "/orders/:id/edit", component: (props) => <OrderOperations type={'EDIT'} />},
  { path: "/orders/:id/details", component: (props) => <OrderDetails type={'EDIT'} />},

  { path: "/galleries/add", component: (props) => <GallaryOperations type={'ADD'} />},
  { path: "/galleries/:id/edit", component: (props) => <GallaryOperations type={'EDIT'} />},

  { path: "/query", component: Query},

  //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/users" /> }
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register }
]

export { authProtectedRoutes, publicRoutes }
