/* TASKS */
export const SPONSER_LOADING_1 = 'SPONSER_LOADING_1'
export const SPONSER_LOADING_2 = 'SPONSER_LOADING_2'
export const GET_SPONSERS = 'GET_SPONSERS'
export const GET_SPONSERS_SUCCESS = 'GET_SPONSERS_SUCCESS'
export const GET_SPONSERS_FAIL = 'GET_SPONSERS_FAIL'
export const ADD_SPONSER = 'ADD_SPONSER'
export const GET_SPECIFIC_SPONSER = 'GET_SPECIFIC_SPONSER'
export const UPDATE_SPONSER = 'UPDATE_SPONSER'
export const UPDATE_SPONSER_SUCCESS = 'UPDATE_SPONSER_SUCCESS'
export const DELETE_SPONSER = 'DELETE_SPONSER'
export const DELETE_SPONSER_SUCCESS = 'DELETE_SPONSER_SUCCESS'
export const SPONSER_TEST = 'SPONSER_TEST'
