import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';

const AddImage = ({ show, handleClose, handleFormSubmit }) => {

  const products = useSelector(state => state.Product.list)

  const initialValues = {
  };

  const validationSchema = Yup.object().shape({
    image: Yup.string().required('Product is required'),
  });

  const handleSubmit = (values) => {
    handleFormSubmit(values);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Image</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik initialValues={initialValues} validationSchema={validationSchema} validateOnChange onSubmit={handleSubmit}>
          {({ values, errors, touched, handleChange, setFieldValue, handleBlur, handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>Image File</Form.Label>
                <Form.Control type="file" name="image" onChange={(event) => {
                  setFieldValue("image", event.currentTarget.files[0]);
                }}
                  onBlur={handleBlur} isInvalid={touched.image && errors.image} />
                <Form.Control.Feedback type="invalid">{errors.image}</Form.Control.Feedback>
              </Form.Group>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                <Button type="submit" variant="primary" disabled={isSubmitting}>ADD</Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddImage;
