import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import Dropzone from "react-dropzone"
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from "yup";
import Switch from "react-switch"
import { createNewuser, fetchSpecificNewusers, updateNewuser } from '../../store/actions'
import { message, Spin } from 'antd'
import SearchUser from './component/SearchUser';

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      No
    </div>
  )
}

const OnSymbol = props => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      Yes
    </div>
  )
}

export class NewuserOperations extends Component {
  static propTypes = {
    type: PropTypes.any
  }


  constructor(props) {
    super(props)
    this.state = {
      selectedFiles: [],
      isSubmiting: false
    }
  }


  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    )

    this.setState({ selectedFiles: files })
  }

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  componentDidMount() {
    const { match } = this.props;
    if (this.props.type == "EDIT") {
      console.log('Getting ID', match.params.id)
      this.props.fetchSpecificNewusers(match.params.id)
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="User" breadcrumbItem={`${this.props.type == "ADD" ? 'Add' : 'Update'} User`} />

            <Row>
              <Col xs="12">
                <Spin spinning={this.props.isLoading}>
                  <Card>
                    <CardBody>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          ...(this.props.type == "EDIT" ? this.props.newuser : {}),
                          ...(() => {
                            let returnObject = {};
                            console.log("xxx")
                            if (this.props.newuser?.parent?.id) {
                              returnObject.organizer_id = { value: this.props.newuser?.parent?.id || "", label: `#${this.props.newuser?.parent.id} || ${this.props.newuser?.parent.email} || ${this.props.newuser?.parent.name}` || "" }
                              returnObject.branch_account = true
                            } else {
                              returnObject.organizer_id = { value: 0, label: "Select User" }
                            }
                            return returnObject;
                            // if()
                          })(),
                        }}
                        validationSchema={
                          Yup.object().shape({
                            name: Yup.string().required('Name is required'),
                            email: Yup.string().email().required('Email is required'),
                            phone: Yup.string().required('Phone is required'),
                            password: this.props.type == "EDIT" ? Yup.string() : Yup.string().required('Password is required'),
                            type: Yup.string().required('User type is required'),
                          })}
                        onSubmit={values => {
                          const that = this;
                          this.setState({ isSubmiting: true })
                          values.is_active = values.is_active ? 1 : 0;
                          if (values.branch_account) {
                            values.organizer_id = values.organizer_id.value
                          } else {
                            values.organizer_id = 0
                          }
                          let apiSubmit = null;
                          if (this.props.type == "EDIT") {
                            if (!(values.icon instanceof File)) {
                              delete values['icon']
                            }
                            if (values.password == "") {
                              delete values.password;
                            }
                            values._method = 'patch'
                            this.props.updateNewuser([this.props.match.params.id, values])
                          } else {
                            this.props.createNewuser(values)
                          }
                        }}
                      >
                        {({ errors, status, touched, setFieldValue, validateForm, values, }) => (
                          <Form className="form-horizontal">
                            <Row>

                              <Col sm="6">
                                <div className="mb-3">
                                  <Label for="name" className="form-label">
                                    User Name *
                                  </Label>
                                  <Field
                                    name="name"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.name
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage name="name" render={msg => <div className="error">{msg}</div>} />
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label for="text" className="form-label">
                                    User phone *
                                  </Label>
                                  <Field
                                    name="phone"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.phone
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage name="phone" render={msg => <div className="error">{msg}</div>} />
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label for="text" className="form-label">
                                    User email *
                                  </Label>
                                  <Field
                                    name="email"
                                    type="email"
                                    className={
                                      "form-control" +
                                      (errors.email
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage name="email" render={msg => <div className="error">{msg}</div>} />
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label for="text" className="form-label">
                                    User Password
                                  </Label>
                                  <Field
                                    name="password"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.password
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage name="password" render={msg => <div className="error">{msg}</div>} />
                                </div>
                              </Col>

                              <Col sm="6">
                                <div className="mb-3">
                                  <Label for="text" className="form-label">
                                    User Type*
                                  </Label>
                                  <Field component="select" name="type" className={
                                    "form-control" +
                                    (errors.type
                                      ? " is-invalid"
                                      : "")
                                  }>
                                    <option value={""}>
                                      --- Select Type ---
                                    </option>
                                    <option value={"user"}>
                                      User
                                    </option>
                                    <option value={"admin"}>
                                      Admin
                                    </option>
                                    <option value={"business"}>
                                      Business
                                    </option>
                                  </Field>
                                  <ErrorMessage name="type" render={msg => <div className="error">{msg}</div>} />
                                </div>
                              </Col>
                              {values.type == "business" && (
                                <Col sm="6">
                                  <div className="mb-3">
                                    <Label for="text" className="form-label">
                                      Business Level*
                                    </Label>
                                    <Field component="select" name="level_type" className={
                                      "form-control" +
                                      (errors.level_type
                                        ? " is-invalid"
                                        : "")
                                    }>
                                      <option value={""}>
                                        --- Select Business level ---
                                      </option>
                                      <option value={"b_to_b_l1_price"}>
                                        B To B Level 1
                                      </option>
                                      <option value={"b_to_b_l2_price"}>
                                        B To B Level 2
                                      </option>
                                      <option value={"b_to_b_l3_price"}>
                                        B To B Level 3
                                      </option>
                                    </Field>
                                    <ErrorMessage name="level_type" render={msg => <div className="error">{msg}</div>} />
                                  </div>
                                </Col>
                              )}

                              <Col sm="6">
                                <div className="mb-3">
                                  <Label className='pr-3'>User Status</Label> <br />
                                  <Switch
                                    uncheckedIcon={<Offsymbol />}
                                    className="me-1 mb-sm-8 mb-2"
                                    checkedIcon={<OnSymbol />}
                                    onColor="#626ed4"
                                    onChange={() =>
                                      setFieldValue('is_active', values.is_active ? !true : !false)
                                    }
                                    checked={values.is_active ? true : false}
                                  />
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label className='pr-3'>Branch Account</Label> <br />
                                  <Switch
                                    uncheckedIcon={<Offsymbol />}
                                    className="me-1 mb-sm-8 mb-2"
                                    checkedIcon={<OnSymbol />}
                                    onColor="#626ed4"
                                    onChange={() =>
                                      setFieldValue('branch_account', values.branch_account ? !true : !false)
                                    }
                                    checked={values.branch_account ? true : false}
                                  />
                                </div>
                              </Col>
                              {values.branch_account && (
                                <Col sm="6">
                                  <div className="mb-3">
                                    <SearchUser values={values} setFieldValue={setFieldValue} label='Select Parent Account:' formikKeyName='organizer_id' />

                                  </div>
                                </Col>
                              )}

                            </Row>


                            <div className="d-flex flex-wrap gap-2 mt-4">
                              <Button
                                type="submit"
                                color="primary"
                                disabled={this.props.isLoading}
                              >

                                {this.props.isLoading ? ' Saving....' : 'Save Changes'}
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Formik>

                    </CardBody>
                  </Card>
                </Spin>

              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment >
    )
  }
}

const mapStateToProps = (state) => ({
  newuser: state.Newuser.single,
  isLoading: state.Newuser.isLoading
})

const mapDispatchToProps = dispatch => ({
  createNewuser: payload => dispatch(createNewuser(payload)),
  updateNewuser: (payload) => dispatch(updateNewuser(payload)),
  fetchSpecificNewusers: payload => dispatch(fetchSpecificNewusers(payload))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewuserOperations))

